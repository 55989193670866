import React, { useContext, useState } from "react";
import BrynsonTabs from "../../shared/BrynsonTabs";
import BrynsonEmbeddedDocuments from "../../shared/BrynsonEmbeddedDocuments";
import { GlobalContext } from "../../context/GlobalContext";
import DailyView from './DailyView';
import MonthlyView from './MonthlyView';

export default function Conversions() {
  const { currentCompanyData } = useContext(GlobalContext);
  const about = currentCompanyData?.currentUser?.currentCompany?.about;
  const [selectedTab, setSelectedTab] = useState("Daily");

  const tabs = [
    { label: "Daily" },
    { label: "Monthly" },
  ];

  return (
    <div className="p-8">
      <h1 className="text-xl font-semibold mb-2">ILS Performance Data</h1>
      {about === 'Olympus Property' ? (
        <BrynsonTabs className="mt-4" tabs={tabs}>
          <DailyView />
          <MonthlyView />
        </BrynsonTabs>
      ) : (
        <BrynsonTabs className="mt-4" tabs={tabs}>
          <BrynsonEmbeddedDocuments documentType="ils_conversion" />
          <>Coming Soon</>
          <>Coming Soon</>
          <>Coming Soon</>
          <>Coming Soon</>
          <>Coming Soon</>
        </BrynsonTabs>
      )}
    </div>
  );
}



