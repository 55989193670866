require("@rails/ujs").start()
require("turbolinks").start()
require("channels/consumer")
require("swiper")
require("../src/application/setup")
require("../src/application/misc")
require("../src/application/tabs")
require("../src/application/swipers")
require("../src/application/forms")
require("../src/application/toggle-controls")
require("../src/application/homepage")
require("../src/application/intlTelInput")
require("../src/application/utils")
import "controllers"
import "../stylesheets/application.css"
import NiceModal from "@ebay/nice-modal-react"
import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter as Router } from "react-router-dom"
import { Provider, createClient } from "urql"

import GlobalProvider from "../components/context/GlobalContext"
import Homepage from "../components/homepage/Homepage"
import Account from "../components/portal/accounts/Account"
import ActivityLog from "../components/portal/activity_log/ActivityLog"
import Availability from "../components/portal/availabilities/Availability"
import Benefits from "../components/portal/benefits/Benefits"
import ComingSoon from "../components/portal/coming_soons/ComingSoon"
import CompAnalysis from "../components/portal/comp_analysis/CompAnalysis"
import CompAnalysisReport from "../components/portal/comp_analysis/CompAnalysisReport"
import CompAnalysisReports from "../components/portal/comp_analysis/CompAnalysisReports"
import CompAnalysisReportsNew from "../components/portal/comp_analysis/CompAnalysisReportsNew"
import CompAnalysisReportsEdit from "../components/portal/comp_analysis/CompAnalysisReportsEdit"
import Concession from "../components/portal/concessions/Concession"
import ConsumerFusion from "../components/portal/consumer_fusions/ConsumerFusion"
import Conversions from "../components/portal/conversions/Conversions"
import DCAA from "../components/portal/dcaas/DCAA"
import DCAAS from "../components/portal/dcaas/DCAAS"
import Deposit from "../components/portal/deposits/Deposit"
import Digible from "../components/portal/digibles/Digible"
import Fee from "../components/portal/fees/Fee"
import Floorplan from "../components/portal/floorplans/Floorplan"
import Geofencing from "../components/portal/geofencings/Geofencing"
import Incidents from "../components/portal/incidents/Incidents"
import HRJobs from "../components/portal/jobs/HRJobs"
import Information from "../components/portal/informations/Information"
import Integrations from "../components/portal/integrations/Integrations"
import IntegratedView from "../components/portal/integrated_view/IntegratedView"
import KeywordMonitoring from "../components/portal/keyword_monitoring/KeywordMonitoring"
import LeaseLabs from "../components/portal/lease_labs/LeaseLabs"
import ManageListings from "../components/portal/listings/ManageListings"
import MarketData from "../components/portal/market_data/MarketData"
import Media from "../components/portal/media/Media"
import MediaAll from "../components/portal/media/MediaAll"
import MondayReport from "../components/portal/monday_report/MondayReport"
import OfficeHour from "../components/portal/office_hours/OfficeHour"
import PaidAds from "../components/portal/paid_ad/PaidAds"
import Park from "../components/portal/parks/Park"
import Paul from "../components/portal/pauls/Paul"
import Payment from "../components/portal/payments/Payment"
import PetPolicy from "../components/portal/pet_policies/PetPolicy"
import PMSData from "../components/portal/pms_data/PMSData"
import PersonalSettings from "../components/portal/settings/Personal"
import PropertyDirectory from "../components/portal/properties/PropertyDirectory"
import PropertyUpload from "../components/portal/properties/PropertyUpload"
import PropertyDirectories from "../components/portal/property_directories/PropertyDirectory"
import ILSRates from "../components/portal/ils_rates/ILSRates"
import PropertyDirectoryInfo from "../components/portal/property_directories/PropertyDirectoryInfo"
import QuarterlyAudits from "../components/portal/quarterly_audits/QuarterlyAudits"
import AIRecommendations from "../components/portal/recommendations/AIRecommendations"
import ReputationManagement from "../components/portal/reputation_management/ReputationManagement"
import SEOAudits from "../components/portal/seo_audits/SEOAudits"
import Soci from "../components/portal/socis/Soci"
import SourceTracking from "../components/portal/source_tracking/SourceTracking"
import Tracking from "../components/portal/tracking/Tracking"
import Unit from "../components/portal/units/Unit"
import ILSIntelligence from "../components/portal/ils_intelligence/ILSIntelligence"
import SideNavigation from "../components/SideNavigation"
import ChatWidget from "../components/paul/ChatWidget"
import IntercomTracker from "../components/IntercomTracker"
import reactUJS from "../support/react_ujs"


document.addEventListener('turbolinks:load', () => {
  setTimeout(() => {
    if (window.Intercom) {
      console.log('Tracking page view:', window.location.pathname);
      window.Intercom('trackEvent', 'page_viewed', {
        page: window.location.pathname,
        url: window.location.href,
        title: document.title,
        timestamp: new Date().toISOString()
      });
    } else {
      console.log('Intercom not available yet');
    }
  }, 1000);
});

const components = {
  Homepage,
  SideNavigation,
  ChatWidget,
  PersonalSettings,
  PropertyDirectory,
  PropertyDirectories,
  ILSRates,
  Floorplan,
  Unit,
  ManageListings,
  AIRecommendations,
  Conversions,
  PMSData,
  SEOAudits,
  CompAnalysis,
  CompAnalysisReport,
  CompAnalysisReports,
  CompAnalysisReportsNew,
  CompAnalysisReportsEdit,
  MarketData,
  KeywordMonitoring,
  ReputationManagement,
  PetPolicy,
  Concession,
  Deposit,
  Availability,
  Information,
  IntegratedView,
  OfficeHour,
  Park,
  Account,
  Payment,
  Fee,
  PropertyUpload,
  Media,
  MediaAll,
  MondayReport,
  PaidAds,
  Geofencing,
  Incidents,
  HRJobs,
  Integrations,
  DCAA,
  DCAAS,
  QuarterlyAudits,
  Benefits,
  ComingSoon,
  ActivityLog,
  SourceTracking,
  Tracking,
  LeaseLabs,
  Digible,
  Soci,
  ConsumerFusion,
  Paul,
  PropertyDirectoryInfo,
  ILSIntelligence,
  IntercomTracker
}

let client

const renderComponent = (component, props, target) => {
  const element = React.createElement(component, props)

  ReactDOM.render(
    <Router>
      <NiceModal.Provider>
        <Provider value={client}>
          <GlobalProvider>{element}</GlobalProvider>
        </Provider>
      </NiceModal.Provider>
    </Router>,
    target
  )
}

const onBeforeMount = () => {
  const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute("content")

  client = createClient({
    url: "/graphql",
    fetchOptions: {
      headers: {
        "X-CSRF-Token": csrfToken
      }
    }
  })
}

reactUJS({
  components,
  renderComponent,
  onBeforeMount,
  loadEventName: "turbolinks:load"
})
