import { Table } from "flowbite-react"
import React from "react"

export default function AudienceSection({ property }) {
  return (
    <div>
      {property.semrushData.some((x) => x.semrushType === "audience") && (
        <div className="mt-6 w-full text-black">
          <h2 className="mb-2 text-lg font-semibold">Where do your leads spend most of their time?</h2>
          <p className="mb-2 text-base font-semibold text-grey">
            When they're not apartment hunting, your leads also spend time on these platforms.
          </p>
          <p className="mb-4 text-base font-semibold text-grey">
            If any of these have an advertising program, they may be worth considering.
          </p>
          <Table hoverable={true} className="text-md mt-4 text-black">
            <Table.Head>
              <Table.HeadCell>Target</Table.HeadCell>
              <Table.HeadCell>Categories</Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              {property.semrushData
                .filter((x) => x.semrushType === "audience")
                .flatMap((x) => x.data)
                .map((row, rowIndex) => (
                  <Table.Row key={rowIndex} data={row}>
                    <Table.Cell>{row.target}</Table.Cell>
                    <Table.Cell>{row.categories}</Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </div>
      )}
    </div>
  )
}
