import React, { useMemo } from "react";
import { useQuery } from 'urql';
import AGGridTable from '../../shared/AGGridTable';
import USAMap from '../../shared/USAMap';
import { interpolateBlues } from 'd3-scale-chromatic';

export const HR_JOBS_QUERY = `
  query {
    hrJobs {
      id
      jobId
      jobName
      openDate
      daysOpen
      closeDate
      jobBoardStatus
      candidatesAdded
      activeCandidates
      candidatesRejected
      hiringManagers
      department
      offices
      offersCreated
      offersAccepted
      offersExtended
      offersRejected
      jobTitle
      state
      region
    }
  }
`;

export default function HRJobs() {
  const [{ data: hrJobsData }] = useQuery({
    query: HR_JOBS_QUERY
  });

  const columns = [
    { accessor: "jobName", title: "Job Name", type: "text" },
    { accessor: "jobId", title: "Job ID", type: "text" },
    { accessor: "openDate", title: "Open Date", type: "date" },
    { accessor: "daysOpen", title: "Days Open", type: "number" },
    { accessor: "jobBoardStatus", title: "Job Board Status", type: "text" },
    { accessor: "candidatesAdded", title: "Candidates Added", type: "number" },
    { accessor: "activeCandidates", title: "Active Candidates", type: "number" },
    { accessor: "hiringManagers", title: "Hiring Managers", type: "text" },
    { accessor: "department", title: "Department", type: "text" },
    { accessor: "offices", title: "Offices", type: "text" },
    { accessor: "jobTitle", title: "Job Title", type: "text" },
    { accessor: "state", title: "State", type: "text" },
    { accessor: "region", title: "Region", type: "text" },
  ];

  const mapData = useMemo(() => {
    if (!hrJobsData?.hrJobs) return [];

    const stateJobCounts = {};
    hrJobsData.hrJobs.forEach(job => {
      if (!job.closeDate) {
        stateJobCounts[job.state] = (stateJobCounts[job.state] || 0) + 1;
      }
    });

    return Object.entries(stateJobCounts).map(([state, count]) => ({
      state,
      openJobs: count
    }));
  }, [hrJobsData]);

  if (!hrJobsData) {
    return <>Loading...</>
  }

  return (
    <div className="w-full p-8">
      <div>
        <h1 className="font-semibold text-xl">Olympus Sample Jobs Summary Report</h1>
          <h2 className="text-md font-semibold my-4">Open Jobs by State</h2>
          	<div className="flex justify-center">
		          <div className="w-[600px] h-[400px] ">
		            <USAMap
								  data={mapData}
								  valueKey="openJobs"
								  colorScale={interpolateBlues}
								  stateKey="state"
								/>
	          </div>
	        </div>
        <div className="w-full bg-white rounded-lg my-4">
          <h2 className="text-md font-semibold mb-4">Jobs Data</h2>
          <div>
            <AGGridTable
              data={hrJobsData?.hrJobs || []}
              headerColumns={columns}
              customCellRenderers={{}}
              customHeaderRenderers={{}}
            />
          </div>
        </div>
      </div>
    </div>
  )
}