import React, { useRef, useState, useContext } from "react"
import PropertyForm from "./PropertyForm"

import BrynsonSquareButton from "./BrynsonSquareButton"
import DesktopH5 from "./DesktopH5"
import Modal from "./Modal"
import { GlobalContext } from "../context/GlobalContext"

export default function CreatePropertyModal({ showCreateProperty, close }) {
  const [loading, setLoading] = useState(false)
  const propertyFormRef = useRef(null)

  const { propertyReload } = useContext(GlobalContext)

  return (
    <Modal
      open={showCreateProperty}
      closeModal={() => {
        close()
      }}
      header={
        <>
          <DesktopH5 content="Add new property" />
          <p className="text-base text-grey">Email or Phone required</p>
        </>
      }
      footer={
        <>
          <BrynsonSquareButton
            type="tertiary"
            onClick={() => {
              close()
            }}
            label="Go back"
          />
          <BrynsonSquareButton
            type="primary"
            testId="add-new-property-button"
            loading={loading}
            onClick={() => {
              if (propertyFormRef.current) {
                setLoading(true)
                propertyFormRef.current.save()
              }
            }}
            label="Add new property"
          />
        </>
      }>
        <PropertyForm
          ref={propertyFormRef}
          afterSave={(success) => {
            setLoading(false)
            if (success) {
              propertyReload()
              close(true)
            }
          }}
        />
    </Modal>
  )
}
