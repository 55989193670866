import { ResponsiveBarCanvas } from "@nivo/bar"
import { Label, Select } from "flowbite-react"
import React, { useState } from "react"
import { HiExclamationCircle } from "react-icons/hi"

import WeeklyMarketActivityUnitsTable from "./WeeklyMarketActivityUnitsTable"

import UnitsTable from "../../../shared/UnitsTable"


export function RentComparison({ data = [], properties= [] }) {
  const [by, setBy] = useState("rent") // or "rent_psf"
  const [filteredProperties, setFilteredProperties] = useState(null)
  const [propertyName, setPropertyName] = useState(null)

  const handleBarClick = (point) => {
    setPropertyName(point.indexValue)
    setFilteredProperties(properties.filter((pr) => pr.id === point.data.id))
  }

  const handleClearFilter = () => {
    setFilteredProperties(null)
    setPropertyName(null)
  }

  return (
    <div className="mt-8 rounded-md border">
      <div className="flex items-center justify-between p-4">
        <div>
          <p className="mb-1 text-xl font-semibold">Rent Comparison</p>
          <p className="text-xs font-medium text-gray-500">
            Quickly compare asking and effective rents across your selected rent comps.
          </p>
        </div>
        <div className="flex items-center space-x-8">
          <div className="flex items-center">
            <Label className="m-0 mr-2">Metric</Label>
            <Select onChange={(e) => setBy(e.target.value)} className="min-w-[8rem] font-medium">
              <option value="rent">Rent</option>
              <option value="rent_psf">Rent PSF</option>
            </Select>
          </div>
        </div>
      </div>
      <div className="h-80">
        {by === "rent" ? (
          <ResponsiveBarCanvas
            data={data}
            keys={["Avg Asking Rent", "Avg Effective Rent"]}
            colors={{ scheme: "paired" }}
            indexBy="property"
            groupMode="grouped"
            valueFormat=" >-$"
            innerPadding="16"
            borderRadius={4}
            margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
            onClick={handleBarClick}
            legends={[
              {
                dataFrom: "keys",
                anchor: "top-right",
                direction: "row",
                justify: false,
                translateX: -600,
                translateY: -30,
                itemWidth: 160,
                itemHeight: 20,
                itemsSpacing: 2,
                symbolSize: 20,
                itemDirection: "left-to-right"
              }
            ]}
          />
        ) : (
          <ResponsiveBarCanvas
            data={data}
            keys={["Avg Asking Rent PSF", "Avg Effective Rent PSF"]}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            indexBy="property"
            groupMode="grouped"
            borderRadius={4}
            valueFormat=" >-$"
            innerPadding="16"
            margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
            onClick={handleBarClick}
            legends={[
              {
                dataFrom: "keys",
                anchor: "top-right",
                direction: "row",
                justify: false,
                translateX: -600,
                translateY: -30,
                itemWidth: 160,
                itemHeight: 20,
                itemsSpacing: 2,
                symbolSize: 20,
                itemDirection: "left-to-right"
              }
            ]}
          />
        )}
      </div>
      <div className="ml-3 mr-3">
          <div className="my-2 text-left">
            {filteredProperties ? (
              <>
                <p className="mb-4 font-bold">
                  {filteredProperties[0]['building_availability'].length} properties in {propertyName}{" "}
                  <button className="text-dark-grey hover:underline" onClick={handleClearFilter}>
                    ✖
                  </button>
                </p>
                <UnitsTable properties={filteredProperties} />
                </>
            ) : (
              <p>Click on a point in time on the chart above to see the units that make it up.</p>
            )}
          </div>
        </div>
    </div>
  )
}

export function WeeklyMarketActivity({ data = [], desc = "", units = [], onClick = () => {}, notice = true }) {
  return (
    <div className="mt-8 rounded-md border">
      <div className="flex items-center justify-between p-4">
        <div>
          <p className="mb-1 text-xl font-semibold">Weekly Market Activity</p>
          <p className="text-xs font-medium text-gray-500">
            Monitor absorption trends across your comps with detailed data on listings added/removed.
          </p>
        </div>
      </div>
      <div className="h-96">
        <ResponsiveBarCanvas
          data={data}
          keys={["New Available Units", "Leased Units"]}
          colors={{ scheme: "paired" }}
          indexBy="week"
          groupMode="grouped"
          innerPadding="16"
          borderRadius={4}
          onClick={onClick}
          margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
          legends={[
            {
              dataFrom: "keys",
              anchor: "top-right",
              direction: "row",
              justify: false,
              translateX: -600,
              translateY: -30,
              itemWidth: 160,
              itemHeight: 20,
              itemsSpacing: 2,
              symbolSize: 20,
              itemDirection: "left-to-right"
            }
          ]}
        />
      </div>
      {units.length ? (
        <div className="p-4">
          <p className="mb-4 font-semibold">{desc}</p>
          <WeeklyMarketActivityUnitsTable units={units} />
        </div>
      ) : null}

    </div>
  )
}

export function getIdFromUrl() {
  const url = window.location.pathname
  const id = url.split("/").pop()
  return id
}

export function isDateRangeActive(from_date, to_date) {
  const today = new Date()
  const from = new Date(from_date)
  const to = new Date(to_date)
  return today >= from && today <= to
}

export const ReportChartField = {
  LeasedUnits: "Leased Units",
  NewAvailableUnits: "New Available Units"
}
