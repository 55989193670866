import React, { useState, useMemo } from 'react';
import { exportCSV, transformDataForChart, formatAllValues, formatTableHeaders } from './PaulChartUtils';
import { renderTable, renderChart } from './PaulChartRenderers.jsx';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';

const DataFrame = ({ df, sql, onSaveChart }) => {
  const [chartType, setChartType] = useState('table');
  const data = JSON.parse(df);

  // Format all values in the data
  const formattedData = data.map(formatAllValues);

  // Format table headers
  const headers = Object.keys(formattedData[0] || {});
  const formattedHeaders = formatTableHeaders(headers);

  const handleSaveClick = () => {
    onSaveChart(sql, chartType);
  };

  const chartData = useMemo(() => transformDataForChart(formattedData, chartType), [formattedData, chartType]);

  return (
    <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden">
      <div className="p-4 flex justify-between items-center font-montserrat text-sm">
        <div>
          {['table', 'bar', 'pie', 'line'].map(type => (
            <button key={type} onClick={() => setChartType(type)} className={`mr-2 ${chartType === type ? 'font-bold text-bgold' : 'font-medium'}`}>
              {type.charAt(0).toUpperCase() + type.slice(1)}
            </button>
          ))}
        </div>
        <div className="flex flex-row">
          <button onClick={handleSaveClick} className="text-bblue flex items-center">
            Save Chart
          </button>
          <button onClick={() => exportCSV(data, 'Brynsights Paul Export')} className="ml-4 text-bblue flex items-center font-medium">
            Download CSV
            <ArrowDownTrayIcon className="ml-2 h-5 w-5" />
          </button>
        </div>
      </div>
      {!chartData && <div className="p-4 text-red-500">Chart can't be rendered with given data.</div>}
      {chartData && chartType === 'table' && renderTable(formattedData, formattedHeaders)}
      {chartData && chartType !== 'table' && renderChart(chartType, chartData)}
    </div>
  );
};

export default DataFrame;