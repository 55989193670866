import React from 'react';
import { useQuery } from 'urql';
import { QUARTERLY_AUDITS_QUERY } from '../../queries/QuarterlyAudits';
import AGGridTable from '../../shared/AGGridTable';
import { headerColumns } from './quarterlyAuditsTableConfig';
import _ from 'lodash';

export default function LatestQuarterlyAudits() {
  const [{ data: quarterlyAuditsData }] = useQuery({
    query: QUARTERLY_AUDITS_QUERY(true)
  });

  const quarterlyAudits = quarterlyAuditsData?.quarterlyAudits || [];
  const allAuditSources = () =>
    _.uniqBy(quarterlyAudits.map((quarterlyAudit) => quarterlyAudit.auditSources).flat(), "id");

  const customCellRenderers = {};
  const customHeaderRenderers = {};

  if (!quarterlyAuditsData) {
    return <div>Loading...</div>;
  }

  const columnDefs = headerColumns(allAuditSources(), quarterlyAudits);

  return (
    <>
      <AGGridTable
        data={quarterlyAudits}
        headerColumns={columnDefs}
        customCellRenderers={customCellRenderers}
        customHeaderRenderers={customHeaderRenderers}
        footerRowStyle={{ backgroundColor: '#c89a2c', color: '#fff' }}
      />
    </>
  );
}
