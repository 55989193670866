import dayjs from "dayjs"
dayjs.extend(require("dayjs/plugin/isBetween"))
dayjs.extend(require("dayjs/plugin/utc"))
dayjs.extend(require("dayjs/plugin/timezone"))
import { Breadcrumb } from "flowbite-react"
import React, { useEffect, useState, useContext } from "react"

import BrynsonDatePicker from "../../shared/BrynsonDatePicker"
import BrynsonSquareButton from "../../shared/BrynsonSquareButton"
import BrynsonTabs from "../../shared/BrynsonTabs"
import LoadingButton from "../../shared/LoadingButton"
import Modal from "../../shared/Modal"
import { GlobalContext } from "../../context/GlobalContext"

import { AmenitiesFinishes } from "./CompAnalysisComponents/AmenitiesFinishes/AmenitiesFinishes"
import { AverageRentsByUnitType } from "./CompAnalysisComponents/AverageRents"
import {
  RentComparison,
  WeeklyMarketActivity,
  getIdFromUrl,
  isDateRangeActive,
  ReportChartField
} from "./CompAnalysisComponents/CompAnalysisFunctions"
import { Fees } from "./CompAnalysisComponents/Fees"
import FloorplansCards from "./CompAnalysisComponents/FloorplansCard"
import { FutureAvailability } from "./CompAnalysisComponents/FutureAvailability"
import HistoricalRentTrends from "./CompAnalysisComponents/HistoricalRentTrends"
import HistoricalExposure from "./CompAnalysisComponents/HistoricalExposure"
import { SpecialsConcessions } from "./CompAnalysisComponents/SpecialsConcessions"
import { UnitCollection } from "./CompAnalysisComponents/UnitCollection"
import { PricingRecommendations } from "./CompAnalysisComponents/UnitsLevelInsights/PricingRecommendations"
import { UnitsLevelInsights } from "./CompAnalysisComponents/UnitsLevelInsights/UnitsLevelInsights"
import { UnitsSubject } from "./CompAnalysisComponents/UnitsLevelInsights/UnitsSubject"
import { fetchProperty, fetchPropertyComparables } from "./CompAnalysisReportsNew"
import { EstimatedUnitMix } from "./EstimatedUnitMix"
import { Property } from "./Property"
import { RentCompsTable } from "./RentCompsTable"
import { FinancialAnalysis } from "./CompAnalysisComponents/FinancialAnalysis/FinancialAnalysis"

export default function CompAnalysisReport() {
  const { currentUserData } = useContext(GlobalContext)
  const userEmail = currentUserData?.currentUser?.email
  const id = getIdFromUrl()
  const [reportCreatedAt, setReportCreatedAt] = useState(null)
  const [properties, setProperties] = useState([])
  const [subject_estimate, setSubjectEstimate] = useState({})
  const [name, setName] = useState("")
  const [concessions, setConcessions] = useState([])
  const [pinstances, setPinstances] = useState([]) // instances of class Property
  const [subject, setSubject] = useState(null)
  const [comparables, setComparables] = useState(null)
  const [loading, setLoading] = useState(true)
  const [fetchDataLoading, setFetchDataLoading] = useState(false)
  const [loadingSubject, setLoadingSubject] = useState(true)
  const [filterPropertiesByDateRange, setFilterPropertiesByDateRange] = useState(null)
  const [selectedWeeklyMarketActivity, setSelectedWeeklyMarketActivity] = useState({
    desc: "",
    data: null,
    units: []
  })

  const [filter, setFilter] = useState({ startDate: null, endDate: null })
  const [showExportModal, setShowExportModal] = useState(false)
  const [exportType, setExportType] = useState("")

  const handleRequestExport = (type) => {
    setExportType(type)
    setShowExportModal(true)
  }

  const handleExportConfirm = async () => {
    try {
      const response = await fetch('/api/request_export', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({
          type: exportType,
          userEmail,
          reportName: name
        }),
      });

      if (response.ok) {
        setShowExportModal(false);
      } else {
        console.log('hit an error')
      }
    } catch (error) {
      console.error('Error sending export request:', error);
      alert('An error occurred while sending the export request. Please try again later.');
    }
  }

  const handleStartDateChange = (date) => {
    if (filter.endDate && new Date(date) >= new Date(filter.endDate)) {
      alert("End date must be after the start date.")
      return false
    }
    setFilter({ ...filter, startDate: date })
  }

  const handleReset = () => {
    setFilter({ startDate: null, endDate: null })
  }

  const handleEndDateChange = (date) => {
    if (filter.startDate && new Date(filter.startDate) >= new Date(date)) {
      alert("End date must be after the start date.")
      return false
    }
    setFilter({ ...filter, endDate: date })
  }

  const handleFetchDataFromJson = async () => {
    const res = await fetch(`/hello_data/reports/${getIdFromUrl()}.json`)
    const data = await res.json()

    setReportCreatedAt(new Date(data.updated_at))

    fetchReport(data)
  }

  const updateReport = async ({ subject, comparables }) => {
    const reportId = getIdFromUrl()

    const res = await fetch(`/hello_data/reports/${reportId}.json`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({
        subject: subject,
        comparables: comparables
      })
    })

    if (res.ok) {
      await handleFetchDataFromJson()
      return true
    } else {
      alert("Failed to update report.")
      return false
    }
  }

  const handleFetchDataFromEndpoints = async () => {
    setFetchDataLoading(true)
    setLoadingSubject(true)
    const subjectData = await fetchProperty(subject.id)
    const comparablesData = await Promise.all(comparables.map((comparable) => fetchProperty(comparable.id)))

    const result = await updateReport({
      subject: subjectData,
      comparables: comparablesData
    })

    if (result) {
      window.location.reload()
    }
  }

  const handleTabSelected = (index) => {
    if (window.Intercom) {
      const tabNames = ['rent_comps', 'unit_level_insights', 'fees_and_amenities', 'financial_analysis'];
      window.Intercom('trackEvent', 'report_tab_viewed', {
        tab: tabNames[index],
        report_name: name,
        location: window.location.pathname,
        timestamp: new Date().toISOString()
      });
    }
  };

  async function fetchReport(data) {
    if (id) {
      const subject = data.data.subject
      const comparables = data.data.comparables
      const subject_estimate = data?.data?.expense_benchmarks || {}
      const properties = [subject, ...comparables]
      let combinedData = []
      if (subject.specials_history && subject.specials_history.length > 0) {
        subject.specials_history.forEach((special) => {
          const active = isDateRangeActive(special.from_date, special.to_date)
          combinedData.push({
            ...special,
            building_name: subject.building_name,
            active: active
          })
        })
      }

      if (subject.concessions_history && subject.concessions_history.length > 0) {
        subject.concessions_history.forEach((special) => {
          const active = isDateRangeActive(special.from_date, special.to_date)
          combinedData.push({
            ...special,
            building_name: subject.building_name,
            active: active
          })
        })
      }

      comparables.forEach((comparable) => {

        if (comparable.specials_history && comparable.specials_history.length > 0) {
          comparable.specials_history.forEach((special) => {
            const active = isDateRangeActive(special.from_date, special.to_date)
            combinedData.push({
              ...special,
              building_name: comparable.building_name,
              active: active
            })
          })
        }
      })

      comparables.forEach((comparable) => {

        if (comparable.concessions_history && comparable.concessions_history.length > 0) {
          comparable.concessions_history.forEach((special) => {
            const active = isDateRangeActive(special.from_date, special.to_date)
            combinedData.push({
              ...special,
              building_name: comparable.building_name,
              active: active
            })
          })
        }
      })


      // fetch comparables which contains similarity_score
      const { comparables: comps = [] } = await fetchPropertyComparables({
        topN: comparables.length,
        subject,
        selected_ids: comparables.map((c) => c.id)
      })

      setPinstances([
        new Property(subject, { isSubject: true }),
        ...comparables.map(
          (comparable) =>
            new Property(comparable, {
              similarity_score: comps.find((c) => c.id === comparable.id)?.similarity_score
            })
        )
      ])
      setName(data.name)
      setSubject(subject)
      setComparables(comparables)
      setProperties(properties)
      setFilterPropertiesByDateRange(UnitCollection.filterPropertiesByDateRange(properties, filter))
      setConcessions(UnitCollection.filterData(combinedData, filter))
      setLoading(false)
      setLoadingSubject(false)
      setSubjectEstimate(subject_estimate)
    }
  }

  const rentComparisonChartData = Object.entries(
    UnitCollection.fromProperties(properties, filter).groupByProperty()
  ).map(([propertyId, collection]) => ({
    id: propertyId,
    property: collection.name,
    "Avg Asking Rent": collection.avgAskingRent,
    "Avg Asking Rent PSF": collection.avgAskingRentPSF,
    "Avg Effective Rent": collection.avgEffectiveRent,
    "Avg Effective Rent PSF": collection.avgEffectiveRentPSF
  }))

  const units = UnitCollection.fromProperties(properties).units

  const weeks = [4, 3, 2, 1, 0].map((i) => {
    const date = dayjs().subtract(i, "week")
    const week = `${date.startOf("week").format("M/D")} - ${date.endOf("week").format("M/D")}`
    const desc = `${date.startOf("week").format("M/D/YY")} - ${date.endOf("week").format("M/D/YY")}`
    const start = date.startOf("week").format("YYYY-MM-DD")
    const end = date.endOf("week").format("YYYY-MM-DD")
    return {
      week,
      desc,
      start,
      end,
      leased: [],
      available: [],
      [ReportChartField.LeasedUnits]: 0,
      [ReportChartField.NewAvailableUnits]: 0
    }
  })

  units.forEach((unit) => {
    for (const week of weeks) {
      if (dayjs(unit.enter_market || unit.created_on).isBetween(week.start, week.end, "day", "[]")) {
        week.available.push(unit)
        week[ReportChartField.NewAvailableUnits]++
      }
      if (unit.exit_market && dayjs(unit.exit_market).isBetween(week.start, week.end, "day", "[]")) {
        week.leased.push(unit)
        week[ReportChartField.LeasedUnits]--
      }
    }
  })

  useEffect(() => {
    handleFetchDataFromJson()
  }, [filter])

  function onWeeklyMarketActivityClick(data) {
    const week = data.data
    setSelectedWeeklyMarketActivity({
      desc: `${data.id} Between ${week.desc}`,
      data: week,
      units: data.id === ReportChartField.NewAvailableUnits ? week.available : week.leased
    })
  }

  const ReportFilter = () => (
    <div className="mb-8">
      <div className="flex w-full items-center justify-between bg-gray-50">
        <Breadcrumb className="mb-2 px-5 py-3 dark:bg-gray-800">
          <Breadcrumb.Item href="/portal/comp_analysis_reports">Comp Analysis Reports</Breadcrumb.Item>
          <Breadcrumb.Item>{name}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="mb-8 flex w-full items-center justify-between bg-gray-50 px-5">
        <div className="my-4 flex w-[600px] flex-row items-center">
          <p className="mr-3 min-w-[100px] font-normal">Change Date:</p>
          <BrynsonDatePicker inputSize="normal" selectedDate={filter.startDate} onChange={handleStartDateChange} />
          <span className="mx-2">—</span>
          <BrynsonDatePicker selectedDate={filter.endDate} onChange={handleEndDateChange} />
          <BrynsonSquareButton className="ml-2" type="link" onClick={handleReset} label="Reset" size="slim" />
        </div>
        <div className="my-4 flex flex-row items-center">
          {reportCreatedAt && (
            <span className="mx-2 font-normal">
              Report Updated At: {dayjs(reportCreatedAt).tz("America/New_York").format("M/D/YY h:mm A")}
            </span>
          )}
          {!fetchDataLoading ? (
            <BrynsonSquareButton
              className="ml-2"
              type="link"
              onClick={handleFetchDataFromEndpoints}
              label="Fetch Latest Data"
              size="slim"
            />
          ) : (
            <LoadingButton />
          )}
        </div>
      </div>
    </div>
  )
  const tabs = [
    {
      label: "Rent Comps",
      render: () => (
        <div className="m-8 pb-20">
          <ReportFilter />
          {!loading && (
            <>
              <RentCompsTable properties={pinstances} />
              <FloorplansCards properties={filterPropertiesByDateRange} />
              <AverageRentsByUnitType properties={filterPropertiesByDateRange} />
              <EstimatedUnitMix properties={properties} />
              <WeeklyMarketActivity
                data={weeks}
                desc={selectedWeeklyMarketActivity.desc}
                units={selectedWeeklyMarketActivity.units}
                notice={!selectedWeeklyMarketActivity.data}
                onClick={onWeeklyMarketActivityClick}
              />
              <RentComparison data={rentComparisonChartData} properties={properties} />
              <HistoricalRentTrends subject={subject} comparables={comparables} properties={properties}/>
              <HistoricalExposure properties={properties} />
              <FutureAvailability properties={properties} />
              {/* <UnitsAvailable properties={properties} /> */}
            </>
          )}
        </div>
      )
    },
    {
      label: "Unit-Level Insights",
      render: () => (
        <div className="m-8 pb-80">
          <ReportFilter />
          {!loading && (
            <>
              <UnitsSubject properties={filterPropertiesByDateRange} filter={filter} />
              <UnitsLevelInsights properties={filterPropertiesByDateRange} />
              <PricingRecommendations properties={filterPropertiesByDateRange} />
            </>
          )}
        </div>
      )
    },
    {
      label: "Fees & Amenities",
      render: () => (
        <div className="m-8 pb-80">
          <ReportFilter />
          {!loading && (
            <>
              <Fees properties={properties} />
              <SpecialsConcessions concessions={concessions} className="my-8" />
              <AmenitiesFinishes properties={properties} />
            </>
          )}
        </div>
      )
    }
  ]

  if(subject_estimate && Object.keys(subject_estimate).length > 0 ){
    tabs.push(
      {
        label: "Financial Analysis",
        render: () => (
          <div className="m-8 pb-80">
            {!loading && (
              <>
                <FinancialAnalysis subject_estimate={subject_estimate} properties={properties} />
              </>
            )}
          </div>
        )
      }
    )
  }

  return (
    <>
      <div className="flex flex-row justify-between items-center">
        <a href="/portal/comp_analysis#custom_reports" className="text-medium ml-4 mt-4 block text-bblue font-medium">&#8249; Back to list</a>
        <div className="flex flex-row mt-4 mr-4 max-w-[600px]">
          <BrynsonSquareButton
            className="mr-2"
            type="link"
            onClick={() => handleRequestExport('PDF')}
            label="Request PDF Export"
            size="slim"
          />
          <BrynsonSquareButton
            type="link"
            onClick={() => handleRequestExport('Excel')}
            label="Request Excel Export"
            size="slim"
          />
        </div>
      </div>
      {loadingSubject ? (
        <LoadingButton />
      ) : (
        <BrynsonTabs
          className="mt-4"
          tabs={tabs}
          onSelected={handleTabSelected}  // Add this line
        ></BrynsonTabs>
      )}
      <div className="flex w-full text-center my-8">
        <span className="m-auto">
          Powered by{" "}
          <a target="_blank" href="https://app.hellodata.ai/" rel="noreferrer">
            HelloData
          </a>
        </span>
      </div>
      <Modal
        open={showExportModal}
        closeModal={() => setShowExportModal(false)}
        size="small"
        header={
          <div>
            <h3 className="text-xl font-semibold">{`Request ${exportType} Export`}</h3>
          </div>
        }
        footer={
          <>
            <BrynsonSquareButton type="link" onClick={() => setShowExportModal(false)} label="Cancel" />
            <BrynsonSquareButton
              type="primary"
              onClick={handleExportConfirm}
              label="Confirm Request"
            />
          </>
        }>
        <p className="font-medium text-md">When requested, you will have it sent to {userEmail} within 24 hours.</p>
        <p className="font-medium text-md">Report: {name}</p>
      </Modal>
    </>
  )
}
