import React from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  Annotation,
} from 'react-simple-maps';
import { geoCentroid } from 'd3-geo';
import { scaleLinear } from 'd3-scale';
import { interpolateBlues, interpolateReds } from 'd3-scale-chromatic';
import usStates from './usStatesTopo.json';
import statesMap from './statesMap';

const offsets = {
  RI: [30, -8],
  DE: [30, 0],
  MD: [40, 10],
  DC: [35, 0],
  NJ: [34, 1],
  NH: [34, -10],
  MA: [30, -20],
  VT: [30, -15],
  HI: [25, 50],
  AK: [50, -25],
};

const USAMap = ({
  data,
  valueKey = 'pendingCriticalIncidents',
  colorScale = interpolateReds,
  stateKey = 'state'
}) => {
  const dataMap = {};
  data.forEach((item) => {
    const stateCode = item[stateKey];
    const stateName = statesMap[stateCode] || stateCode;
    if (stateName) {
      dataMap[stateName] = item;
    } else {
      console.warn(`State '${stateCode}' not found in statesMap.`);
    }
  });

  const values = data.map((d) => d[valueKey]);
  const maxValue = Math.max(...values);
  const minValue = Math.min(...values);
  const domain = minValue === maxValue ? [minValue, minValue + 1] : [minValue, maxValue];

  const colorScaleFunc = scaleLinear()
    .domain(domain)
    .range([0, 1]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ComposableMap projection="geoAlbersUsa">
        <Geographies geography={usStates}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const stateName = geo.properties.name;
              const stateAbbr = Object.keys(statesMap).find(
                (key) => statesMap[key] === stateName
              ) || stateName;
              const datum = dataMap[stateName] || dataMap[stateAbbr];
              const value = datum ? datum[valueKey] : 0;
              const fillColor = value > 0 ? colorScale(colorScaleFunc(value)) : '#EEE';
              const centroid = geoCentroid(geo);
              const offset = offsets[stateAbbr] || [0, 0];

              return (
                <React.Fragment key={geo.rsmKey}>
                  <Geography
                    geography={geo}
                    fill={fillColor}
                    stroke="#FFF"
                    strokeWidth={0.5}
                    style={{
                      default: { outline: 'none' },
                      hover: { fill: '#F53', outline: 'none' },
                      pressed: { outline: 'none' },
                    }}
                  />
                  {value > 0 && (
                    <Annotation
                      subject={centroid}
                      dx={offset[0]}
                      dy={offset[1]}
                      connectorProps={{
                        stroke: 'none',
                      }}
                    >
                      <text
                        x={4}
                        y={4}
                        textAnchor="middle"
                        style={{ fontSize: 14, fill: 'black', fontWeight: 'bold' }}
                      >
                        {value}
                      </text>
                    </Annotation>
                  )}
                </React.Fragment>
              );
            })
          }
        </Geographies>
      </ComposableMap>
    </div>
  );
};

export default USAMap;