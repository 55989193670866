import React from "react"

export default function OrganicPhrasesSection({ property }) {
  return (
    <div>
      {property.semrushData.some((x) => x.semrushType === "phrase_organic") && (
        <>
          <h2 className="mb-2 text-lg font-semibold">Top websites for most popular rental intent searches.</h2>
          <p className="mb-2 text-base font-semibold text-grey">
            We check the most common search queries in your locality, and show the top results.
          </p>
          <p className="mb-4 text-base font-semibold text-grey">
            The top 3 results get 70% of the clicks, so you want to make sure your property is listed on those
            platforms.
          </p>
          <div className="grid grid-cols-2 gap-4">
            {property.semrushData
              .filter((x) => x.semrushType === "phrase_organic")
              .map(
                (semrushData, semrushDataIndex) =>
                  semrushData.data &&
                  semrushData.data.length > 0 && (
                    <div className="pb-4" key={semrushDataIndex}>
                      <h2 className="font-semibold text-black">"{semrushData.keyword}"</h2>
                      <ul>
                        {semrushData.data.map((data, dataIndex) => (
                          <li key={dataIndex}>
                            <a href={data.url}>{data.url}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )
              )}
          </div>
        </>
      )}
    </div>
  )
}
