export const COMPANIES_QUERY = `
  query {
    companies {
      id
      about
    }
  }
`

export const CURRENT_COMPANY_QUERY = `
  query {
    currentUser {
      currentCompany {
        about
        separateBrynsonFeeIlsSpend
      }
    }
  }
`
