import React, { useState, useMemo } from "react";
import { useQuery } from 'urql';
import AGGridTable from '../../shared/AGGridTable';
import BrynsonDatePicker from "../../shared/BrynsonDatePicker";
import { headerColumns } from './ilsConversionsTableConfig';
import { PROPERTY_INSIGHTS_QUERY } from '../../queries/PropertyInsightsQueries';

const processData = (data) => {
  if (!data?.propertyInsights) return [];
  const groupedInsights = data.propertyInsights.reduce((acc, insight) => {
    if (!acc[insight.property.id]) {
      acc[insight.property.id] = { ...insight.property, propertyInsights: [] };
    }
    acc[insight.property.id].propertyInsights.push(insight);
    return acc;
  }, {});
  return Object.values(groupedInsights);
};

const DailyView = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const formattedDate = selectedDate.toLocaleDateString('en-CA');

  const [{ data, fetching, error }, reexecuteQuery] = useQuery({
    query: PROPERTY_INSIGHTS_QUERY,
    variables: { date: formattedDate },
  });

  const handleDateChange = (date) => {
    setSelectedDate(date);
    reexecuteQuery({ requestPolicy: 'network-only' });
  };

  const processedData = useMemo(() => processData(data), [data]);
  const columnDefs = useMemo(() => headerColumns(processedData, false), [processedData]);

  if (fetching) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      <div className="mb-4 max-w-[150px]">
        <BrynsonDatePicker
          selectedDate={selectedDate}
          onChange={handleDateChange}
          maxDate={new Date()}
        />
      </div>
      <AGGridTable
        data={processedData}
        headerColumns={columnDefs}
        customCellRenderers={{}}
        customHeaderRenderers={{}}
      />
    </div>
  );
};

export default DailyView;
