import React, { useEffect, useState } from "react"
import BrynsonTabs from "../../shared/BrynsonTabs"
import "@react-pdf-viewer/core/lib/styles/index.css"
import DisplayComponent from "./DisplayComponent"

export default function CompAnalysis() {
  const [initialTab, setInitialTab] = useState(0)

  useEffect(() => {
    if (window.location.hash === "#custom_reports") {
      setInitialTab(1)
    }
  }, [])

  const handleTabSelected = (tabIndex) => {
    if (tabIndex === 1) {
      if (window.Intercom) {
        window.Intercom('trackEvent', 'custom_reports_viewed', {
          location: window.location.pathname,
          timestamp: new Date().toISOString(),
          section: 'CompAnalysis'
        });
      }
    }
  }

  const tabs = [
    {
      label: "CoStar Reports",
      render: () => <DisplayComponent compAnalysisType="costar" rand={Math.random()} />
    },
    {
      label: "Custom Reports",
      render: () => <DisplayComponent compAnalysisType="other" rand={Math.random()} />
    }
  ]

  return (
    <div className="p-8">
      <BrynsonTabs
        className="mt-4"
        tabs={tabs}
        initialActiveTab={initialTab}
        onSelected={handleTabSelected}
      ></BrynsonTabs>
    </div>
  )
}