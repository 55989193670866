import React, { useContext } from "react";
 // import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import BrynsonTabs from "../../shared/BrynsonTabs"
import styled from 'styled-components';
import { createGlobalStyle } from "styled-components";
import { CameraIcon, PhotoIcon } from "@heroicons/react/24/solid";
import { GlobalContext } from "../../context/GlobalContext";



export default function Benefits() {
  const { currentUserData } = useContext(GlobalContext);

  const about = currentUserData?.currentUser?.currentCompany?.about;
  const GlobalStyle = createGlobalStyle`
  h1 {
    font-size: 2.0em;
    text-align: left;
    color: #102a4a;
    font-weight: bold;
  }
  h3 {
    font-size: 1.0em;
    text-align: left;
  }
  h4 {
    font-size: 1.2em;
    text-align: left;
    color: black;
  }
  p {
    font-size: 1.0em;
    text-align: left;
    color: black;
  }
  ol {
    list-style-type: decimal !important;
  }
  ul {
    list-style-type: circle !important;
    font-weight: bold;
  }
  `
  const Title = styled.h1`
    font-size: 2.0em;
    text-align: left;
    color: #102a4a;
    font-weight: bold;
  `;
  const TextBlock = styled.p`
    font-size: 1.0em;
    text-align: left;
    color: black;
    margin-left: 20px;
  `;
  const SubTitles = styled.h3`
    font-size: 1.0em;
    text-align: left;

`
  const SubSubTitles = styled.h4`
    font-size: 1.2em;
    text-align: left;
    color: black;
    margin-left: 10px;
`
const OList = styled.ol`
    list-style-type: decimal !important;

    `
const List = styled.ul`
     list-style-type: circle !important;
     font-weight: bold;
`


  const tabs = [
    {
      label: "Photoshoot Process",
      icon: CameraIcon,
    },
    {
      label: "Photoshoot Status",
      icon: PhotoIcon,
    },
  ];


  const companyBenefitsUrls = {
    'Olympus Property': "https://docs.google.com/spreadsheets/d/e/2PACX-1vT6JdQg8iGcNQBFb8FkEiDgRCPXY-prBCouiEGc4dmQShW6Po86Q_wjF1pvIPxxO4e4fPv_uf4IFoha/pubhtml?widget=true&amp;headers=false",
    'Bruns Realty Group': "https://docs.google.com/spreadsheets/d/e/2PACX-1vTg9vlnU0QtWR88ag-064w5Sh4AButdTaltd5mEHQnD-uYW1jl9H-3t-Odqi3_0OQQf53SbsjUMYsCe/pubhtml?widget=true&amp;headers=false",
    'AMBO Properties': "https://docs.google.com/spreadsheets/d/e/2PACX-1vRYZV74o7h34_pF0mD39zo1Cqgf41GlVwbNsHzRb5wehpdNvz8M_birPwMtxIY9jHJxPWcllfyPgzhL/pubhtml?widget=true&amp;headers=false",
    'Aberly Management': "https://docs.google.com/spreadsheets/d/e/2PACX-1vTz3t3jWIpXFloh-ZZe4zr9TPXxOtGaqPt5vdmFI4bxK7tmHHGD0ahDD7DoPEQqKz4vyhL7vdiluOvP/pubhtml?widget=true&amp;headers=false",
    'Bellrock Real Estate Partners LLC': "https://docs.google.com/spreadsheets/d/e/2PACX-1vQNwu4pDKsp530Agcx1EkkFZNesA6gEuT-1VCQGpDTZWCFelJT4ytz5_VPe5qONdHEXVVnVq_twledS/pubhtml?widget=true&amp;headers=false",
    'Chelsea Management': "https://docs.google.com/spreadsheets/d/e/2PACX-1vSkAirJ5RtWNikUOQkFiXWPOhhMUdCkcCxdtd9wHsohv20Ho_FO_cFZJw9uNhz2VNsGq3CxWMCNp3V7/pubhtml?widget=true&amp;headers=false",
    'Dawn Homes Management Properties': "https://docs.google.com/spreadsheets/d/e/2PACX-1vRhqVvVFi5JmJdm27TaEomDeXQQzkzcLgjeiKc9Du1HBL5HVSLcfzUSwT8uzH4OHYr_SrnRpHNTzjfz/pubhtml?widget=true&amp;headers=false",
    'Finning Properties': "https://docs.google.com/spreadsheets/d/e/2PACX-1vQhMM5d8KebpKbjzE4Ymp1PY1_Z2Z__BHp759PgsZRfxR3GSHlGhUkoKR1ptgukKeoLj5dSbM0Q9bZf/pubhtml?widget=true&amp;headers=false",
    'Goldfarb Properties': "https://docs.google.com/spreadsheets/d/e/2PACX-1vQTcnMtKoBfY9vwMoqdcBUQ2urjSMVN-7foS6eh7Pgfmd2vsqPCqpYLHz0XFoqwEgga0Bd1aHm6oAZx/pubhtml?widget=true&amp;headers=false",
    'Judge Development': "https://docs.google.com/spreadsheets/d/e/2PACX-1vQu1u7iRXppv5Y4Hm1BJbcQ9Yf6NACf644Xcz45qNb5q-iuWwDpOr0TX-JDyTONF-mOX1pVXxK5_z8C/pubhtml?widget=true&amp;headers=false",
    'M3 Management': "https://docs.google.com/spreadsheets/d/e/2PACX-1vS4e3JYNEtie0IQAxUiaHJFoLfxI20ZmFk2SRcHG87EHtZz0CoGFcqta9m_6iNyqtbNLcjAFOGi6sZ6/pubhtml?widget=true&amp;headers=false",
    'Olive Tree Management': "https://docs.google.com/spreadsheets/d/e/2PACX-1vT_VESTLABL45COSPbimT0P8JqdQ5zCTF43SqFaLCMMZOtCkX3nNGsZs7Mw0ytVBmM93rpjoRFWvQkF/pubhtml?widget=true&amp;headers=false",
    'Omni Development': "https://docs.google.com/spreadsheets/d/e/2PACX-1vRlRUKQK1L9BVmpKVbvdEtqrjWd5GP4lCrxwbGRYhwoJBD7CDXQy63qOcFY-H-sdb_Yygnvk8ATrLH6/pubhtml?widget=true&amp;headers=false",
    'REM Capital': "https://docs.google.com/spreadsheets/d/e/2PACX-1vQeQOcbm3l4YI5mnRnYO1zjiDqY_96oZQDp5ouTj2mZmmhlcL3d2-IUTaNITI257Q2sAnrwCPsTwoMZ/pubhtml?widget=true&amp;headers=false",
    'Skytian Capital': "https://docs.google.com/spreadsheets/d/e/2PACX-1vTipYJMqhct0FX3mposquIhMqqF_c_7k9k8NIqLBztQwo5tfAchbC3kI6ai1cHKIkdM2gWVAlfj5K7j/pubhtml?widget=true&amp;headers=false",
    'Tri City Rentals': "https://docs.google.com/spreadsheets/d/e/2PACX-1vTSfcJLInZc1MswBnpNQgyaQiuSbcRD3odeibwUn2D_HSpkX7yWZ8y7BLmqc7TIGASiE04S4uHDwW8c/pubhtml?widget=true&amp;headers=false",
    'WPHS Venture Partners': "https://docs.google.com/spreadsheets/d/e/2PACX-1vTo5ZDYCFviSGy8ELniZ9NipX5MohNkj0pmdA1ig1__FW4XY9q70fP4HO33qhIWMZi6zcEYbF3CtZgj/pubhtml?widget=true&amp;headers=false",
    'Westmere Village': "https://docs.google.com/spreadsheets/d/e/2PACX-1vTdCJix4qBkPAHmtxTI_rQbbH1IZIenvEgNjjbPminse9Pqpb-6Q7H_TbH8nm9Tgf-lscckre8YvxhY/pubhtml?widget=true&amp;headers=false",
    'Bruns Realty Group': "https://docs.google.com/spreadsheets/d/e/2PACX-1vS_-I6amUIRZm9eNsZLAFWQRCnR_w6CPcSGF07RHHYBcfYhK2yyLsndjNDWiEQwDYVmcZgw87MrnKaq/pubhtml?widget=true&amp;headers=false",
    'Prime Management LLC': "https://docs.google.com/spreadsheets/d/e/2PACX-1vRPo3OhfKJcSMdqin5qWN1YXc9TU8kS0M9zDawQL95HYtELs_bECaS4mIpGga1gxgExhznYuC4BEDp1/pubhtml?widget=true&amp;headers=false",
    'Velo Residential': "https://docs.google.com/spreadsheets/d/e/2PACX-1vTJapR8lUCHxS_X3qz_FytWEa99L4u-M9vdKulKOAWCF64KYT0KoPurSp9LPMdGjZX-3Dr9ZhAZRbKm/pubhtml?widget=true&amp;headers=false",
    'The Sterling Group': "https://docs.google.com/spreadsheets/d/e/2PACX-1vSKIvRgpp4g1nHyIkjKC-0iYUzAvuuhrxxWYRSfL3YVsbl_TqRNf_MrjI8QNJbKEPAnxF-YUDtp8M6N/pubhtml?widget=true&amp;headers=false",
    'Living Residential': "https://docs.google.com/spreadsheets/d/e/2PACX-1vSbZwfRHGdCaQ4PgGjRmOIxBJvExwe1mMJI1jkIvmV2NlPXvcU8IQtforWFgut0E7SD-Cg-lyaqfjHI/pubhtml?widget=true&amp;headers=false",
    'FLATS': "https://docs.google.com/spreadsheets/d/e/2PACX-1vQQyPc1Y_fT2TVtJI4jjxNqKOY5GRuzZ_A-SdzExU36WAh87etfVNOVpeYVf_tghq_dgmDcFLGykj-k/pubhtml?widget=true&amp;headers=false",
    'Magnolia Management': "https://docs.google.com/spreadsheets/d/e/2PACX-1vRfqxc_I6h9Hb-xnbo9u2lr1HMo9j78SrYHMIdZWQYThoULBRJplGHxrNCLhaiQpk795sV-il7DR6Bl/pubhtml?widget=true&amp;headers=false",
    'Beacon Real Estate Group': "https://docs.google.com/spreadsheets/d/e/2PACX-1vTm-X8QF5WVe4jNG3WziEKes-p2QKss-8cauewBf19dergThwai5uDmjyq2mQypzeKNHQZu9KW4C_k6/pubhtml?widget=true&headers=false"
  };

  const benefitsUrl = companyBenefitsUrls[about] || 'https://docs.google.com/spreadsheets/d/e/2PACX-1vQj0DiJ7CNAwny_g37F4_hXtf0yC11EKvqI1xP1jwN2XxQlYy2O4OHoiC6HsVk2_cLYSr9YjM9mELoh/pubhtml?widget=true&amp;headers=false';

  return (
    <div className="p-8">
      <h1 className="font-semibold text-xl">Photoshoots & Benefits</h1>
      <BrynsonTabs className="mt-4" tabs={tabs}>
      <>

      <h1 class='text-4xl font-bold text-blue-950'>
                Photoshoot Process
             </h1>

           <br/>

       <p>The photoshoot process involves capturing high-quality images, video, and 3D tours of a property for
       marketing purposes. You can avail photoshoots based on the packages that you subscribed to. Below are
       the guidelines involved in conducting a photoshoot for clients, categorized by the respective ILS (Internet
       Listing Service) packages.
      </p>

    <br/>
    <>
    <OList>
    <ol>
     <b>
       <li>
       <SubTitles>
          <h3 class='text-yellow-400 font-bold'>
            1. Package Eligibility Check
          </h3>
        </SubTitles>
       </li>
     </b>
    <br/>
        <TextBlock>
           <p>
           Determine the package associated with the property:
          </p>
          <img src='https://lh3.googleusercontent.com/pw/AP1GczOW4_Q2pGo7V10ef5IlBvdSid_-7yS__4KtK4PEuRGt5J568NliPaB7HLnJDPF7iGSaJiTok0EtNENlickgPgqvSwkR8ZbqoTObGfEBTMU8cL0Cl4m5yQ5tDgRHt4vtalqO_PQy_iKQ7APOucdUBmgs=w1158-h561-s-no-gm?authuser=0' alt='Tables with the guidelines.'/>
       </TextBlock>

       <br/>

        <li>
        <SubTitles>
           <b>
             <h3 class='text-yellow-400 font-bold'>
              2. Photoshoot Availability
             </h3>
           </b>
        </SubTitles>
        <br/>
        </li>
        <TextBlock>
        <p>
           Identify the available dates for a photoshoot.
        </p>
        </TextBlock>
        <br/>
        <b>
        <li>
        <SubTitles>
            <h3 class='text-yellow-400 font-bold'>
              3. Client Notification
            </h3>
         </SubTitles>
         <br/>
        </li>
    </b>
      <TextBlock>
        <p>
             Send an email notification whenever you upgra'de or add a package offering a photoshoot inclusion.
      Include contact details for photoshoot inquiries.
        </p>
         </TextBlock>
         <br/>
    <SubTitles>
     <b>
        <li>
           <h3 class='text-yellow-400 font-bold'>
              4. Photoshoot Preparation Guidelines
           </h3>
        </li>
     </b>
    </SubTitles>
    <br/>
    <TextBlock>
          <p>Once scheduled, here are some tips to prepare for the photoshoot:</p>
    </TextBlock>
    <br/>
    <div>
     <List>
      <ul class='mx-5'>
        <li>
        ● Tidy up interior and exterior amenities.
        </li>
        <li>
        ● Arrange patio and pool furniture.
        </li>
        <li>
        ● Provide staged models or occupied units (without personal effects).
        </li>
        <li>
        ● Open interior blinds for natural light.
        </li>
        <li>
        ● Remove seasonal and holiday decor.
        </li>
        <li>
        ● Manicure property grounds.
        </li>
        <li>
        ● Note: Human models are not allowed to comply with fair housing laws.
        </li>
      </ul>
      </List>
  </div>
  <br/>
  <SubTitles>
    <li>
      <b>
        <h3 class='text-yellow-400 font-bold'>
           5. Frequently Asked Questions (FAQs)
        </h3>
      </b>
      </li>
    </SubTitles>
    </ol>
    </OList>
    <br/>
    </>
    <>
        <TextBlock>
          <p>
               Below, you'll find a list of common questions and answers pertaining to the photoshoot details and policies
     for each ILS.
          </p>
        </TextBlock>
        <br/>

    <SubTitles>
        <label>
           <b>
             <h3 class='text-blue-950'>
               For All ILS:
            </h3>
          </b>
       </label>
    </SubTitles>
    <br/>
    <ul>
    <SubSubTitles>
    <label>
     <li>
      <h4 class='font-bold'>
      ● Are there any fees incurred due to photoshoot cancellations or missed appointments?
      </h4>
     </li>
    </label>
   </SubSubTitles>
   <TextBlock>
      <p>
       Yes, there are fees associated with the photoshoot cancellations or missed appointments.
      </p>
   </TextBlock>
   <br/>
   <SubSubTitles>
   <label>
       <li>
          <h4 class='font-bold'>
          ● How much are the cancellation or missed appointment fees?
        </h4>
     </li>
     </label>


   </SubSubTitles>
      <TextBlock>
       <p>
          The fees will differ depending on the ILS.
       </p>
      </TextBlock>
      <br/>
    <SubSubTitles>


      <li>
      <label>
        <h4 class='font-bold'>
        ● Who is responsible for the fees incurred due to cancellations or missed appointments?
        </h4>
        </label>
      </li>
     </SubSubTitles>
     <TextBlock>
      <p>
        Any fees incurred due to cancellations or missed appointments are the responsibility of the client,
        not Brynson.
      </p>
     </TextBlock>
    </ul>
    <br/>
    <SubTitles>
     <label>
      <b>
         <h3 class='text-blue-950'>
            For Zumper:
         </h3>
      </b>
     </label>
    </SubTitles>
   <br/>
   <ul>
   <SubSubTitles>
   <label>
    <li>
      <b>
        <h4>
        ●  When can I schedule a Zumper photoshoot?
        </h4>
      </b>
    </li>
    </label>
    </SubSubTitles>

       <TextBlock>
          <p>
           Zumper photoshoots can be scheduled anytime.
          </p>
       </TextBlock>
       <br/>
       <SubSubTitles>
       <label>

          <li>
              <b>
                <h4>
                  ● Who covers Matterport hosting fees during the contract with Zumper?
                </h4>
              </b>
          </li>


       </label>
        </SubSubTitles>

   <TextBlock>
        <p>
           Zumper covers Matterport hosting fees during the contract.
        </p>
    </TextBlock>
    <br/>
  <SubSubTitles>
   <label>
        <li>
          <b>
            <h4>
            ● What happens if a property cancels the Zumper advertising package?
            </h4>
          </b>
        </li>
    </label>
  </SubSubTitles>
  <TextBlock>
      <p>
       If a property cancels the Zumper advertising package, you can take over Matterport tour ownership
      and hosting fees, while keeping all the images.
      </p>
  </TextBlock>
  <br/>
    <SubSubTitles>
    <label>
     <li>
       <b>
        <h4>
        ● What is the main difference between Advantage and Advantage Plus?
       </h4>
       </b>
     </li>
     </label>

    </SubSubTitles>
    <TextBlock>
      <p>
         Advantage requires you to choose between virtual staging or 3D tours as your preferred media. In
contrast, Advantage Plus includes both types of media for your convenience.
    </p>
   </TextBlock>
   <br/>

   <SubSubTitles>
   <li>
     <label>
        <b>
          <h4>
          ● What does the Advantage Package include?
          </h4>
        </b>
     </label>
   </li>
    </SubSubTitles>

    <TextBlock>
       <p>
       Matterport 3D tours: Own the hosting rights to your Matterport photoshoot for use across various
       marketing channels.
       </p>
        <p>
          Or
        </p>
    </TextBlock>

      <TextBlock>
        <p>
            Virtual Staging: Select 5 floor plan photos for virtual staging, tailored to match your community's
       unique style.
        </p>
    </TextBlock>
    <br/>

  <label>
       <li>
          <h4>
            <b>
            ● What does the Advantage Plus Package include?
            </b>
          </h4>
        </li>
   </label>
  <TextBlock>
      <p>
        Matterport 3D tours: Own the hosting rights to your Matterport photoshoot for versatile use in your
      marketing efforts.
      </p>
      <p>
      And
      </p>
      <p>
       Virtual Staging: Choose 5 floor plan photos for virtual staging, ensuring a customized presentation
       aligned with your community's style.
      </p>
  </TextBlock>
</ul>
<br/>

       <SubTitles>
          <label>
            <b>
               <h3 class='text-blue-950'>
             For Rent:
                </h3>
            </b>
          </label>
        </SubTitles>
  <br/>
  <ul>
    <SubSubTitles>
      <label>
           <li>
             <b>
               <h4>
               ● What makes a property eligible for the photoshoot benefits?
               </h4>
             </b>
           </li>
         </label>
      </SubSubTitles>
      <TextBlock>
        <p>
          Properties must be advertised on Rent for a minimum duration of 6 months to qualify for the
       photoshoot benefits.
        </p>
      </TextBlock>

       <br/>
      <SubSubTitles>
      <label>
           <li>
             <b>
               <h4>
               ● How many photos, tours, and videos are included?
               </h4>
             </b>
           </li>
         </label>
      </SubSubTitles>
       <TextBlock>
        <p>
         All packages include 25 photos and 5 tours and videos.
        </p>
       </TextBlock>
       <br/>
      <SubSubTitles>
        <label>
           <li>
             <b>
               <h4>
               ● How many photos, tours, and videos are included?
               </h4>
             </b>
           </li>
         </label>
      </SubSubTitles>
       <TextBlock>
       <p>
        Every 3 years.
       </p>
       </TextBlock>
       <br/>

       <SubSubTitles>
      <label>
           <li>
             <b>
               <h4>
               ● What does "scheduling" mean?
               </h4>
             </b>
           </li>
         </label>
        </SubSubTitles>
        <TextBlock>
          <p>
         Indicates SmartShoot's outreach for scheduling a shoot, pending completion.
          </p>
        </TextBlock>
        <br/>
     <SubSubTitles>
      <label>
           <li>
             <b>
               <h4>
               ● How can I check the status of my scheduled photoshoot?
               </h4>
             </b>
           </li>
      </label>
      </SubSubTitles>
        <TextBlock>
          <p>
           Use the scheduling link for details on pending shoots.
          </p>
        </TextBlock>
        <br/>
    <SubSubTitles>
      <label>
           <li>
             <b>
               <h4>
          ● How can I obtain a list for each client showing their property and its status?
               </h4>
             </b>
           </li>
      </label>
     </SubSubTitles>
        <TextBlock>
          <p>
            Send a request to SmartShoot specifying the required details.
          </p>
        </TextBlock>

        <br/>
     <SubSubTitles>
      <label>
           <li>
             <b>
               <h4>
               ●  Regarding photo rights, can the client use these photos on any site, feed, or advertising, or only
on Rent.com?
               </h4>
             </b>
           </li>
         </label>
      </SubSubTitles>

      <TextBlock>
           <p>
           Photos taken by Rent.com can only be used on the Rent.com site. Rent.com does grant
       authorization to use their photos on the property website or any other marketing other than any
       other ILS competitor's site. A signed disclosure by the client is required to utilize this option.
           </p>
       </TextBlock>
    </ul>
    <br/>
    <SubTitles>
          <label>
            <b>
               <h3 class='text-blue-950'>
             For Zillow:
                </h3>
            </b>
          </label>
    </SubTitles>
    <br/>
      <ul>
      <SubSubTitles>
      <label>
           <li>
             <b>
               <h4>
               ● Do Zillow Premium package photo shoots have expiration dates?
               </h4>
             </b>
           </li>
         </label>
      </SubSubTitles>

      <TextBlock>
        <p>
           Yes, with a 60-day expiration from voucher issuance.
        </p>
      </TextBlock>
      <br/>
      <SubSubTitles>
      <label>
           <li>
             <b>
               <h4>
               ● Can the client use photos on any site, feed, or advertising?
               </h4>
             </b>
           </li>
         </label>
      </SubSubTitles>
      <TextBlock>
          <p>
           Yes, photos are not copyrighted and can be used on various platforms.
          </p>
      </TextBlock>
      <br/>
    <SubSubTitles>
      <label>
           <li>
             <b>
               <h4>
               ● How are photos delivered?
               </h4>
             </b>
           </li>
         </label>
    </SubSubTitles>
    <TextBlock>
       <p>
          Photos and content are emailed to the designated contact.
       </p>
    </TextBlock>
    </ul>
    <br/>
    <SubTitles>
        <label>
           <b>
             <h3 class='text-blue-950'>
               For CoStar:
            </h3>
          </b>
       </label>
    </SubTitles>
    <br/>
    <ul>
       <SubSubTitles>
          <label>
           <li>
             <b>
               <h4>
               ● Is the property currently eligible for a new or unused photo shoot?
               </h4>
             </b>
           </li>
         </label>
       </SubSubTitles>
      <TextBlock>
         <p>
           Yes, every 3 years with an eligible package.
          </p>
       </TextBlock>
       <br/>
       <SubSubTitles>
          <label>
           <li>
             <b>
               <h4>
                ● How long does the eligibility last?
               </h4>
             </b>
           </li>
         </label>
       </SubSubTitles>
       <TextBlock>
         <p>
           Indefinitely, as long as the community maintains an eligible package.
         </p>
       </TextBlock>
       <br/>
       <SubSubTitles>
          <label>
           <li>
             <b>
               <h4>
                ●   If ineligible, when was the last included shoot taken advantage of?
               </h4>
             </b>
           </li>
         </label>
       </SubSubTitles>
       <TextBlock>
          <p>
            Refer to the photoshoot status spreadsheet.
          </p>
       </TextBlock>
       <br/>
       <SubSubTitles>
          <label>
           <li>
             <b>
               <h4>
                ●  What does the package include?
               </h4>
             </b>
           </li>
         </label>
       </SubSubTitles>
       <TextBlock>
         <p>
           Refer to the Ad Package Brochure or request a direct inclusion list.
         </p>
       </TextBlock>
       <br/>
       <SubSubTitles>
          <label>
           <li>
             <b>
               <h4>
                ●  Can CoStar photos be used on other sites or other marketing materials?
               </h4>
             </b>
           </li>
         </label>
       </SubSubTitles>
      <TextBlock>
         <p>
           CoStar photos are owned by CoStar and unfortunately, they do not allow their photos to be used
        anywhere else. Costar will watermark their photos in the lower right corner of each photo with their
        brand logo.
         </p>
      </TextBlock>
      <br/>
    </ul>
    </>
  </>
      <>
          <iframe
            src={benefitsUrl}
            frameBorder="0"
            width="100%"
            height="900"
          />
        </>
      </BrynsonTabs>
    </div>
  );
}
