import React, { useState } from "react"
import { useQuery } from "urql"
import Select from "react-select"
import { selectStyling } from "../../../utils/utils"
import { QUARTERLY_AUDITS_QUERY } from "../../queries/QuarterlyAudits"
import AGGridTable from '../../shared/AGGridTable';

import { headerColumns } from "./quarterlyAuditsTableConfig"

const ImageHeaderRenderer = (props) => {
  return <img src={props.imageUrl} alt={props.displayName} style={{ maxWidth: '100%', maxHeight: '40px' }} />;
};

export default function HistoryQuarterlyAudits() {
  const [{ data: quarterlyAuditsData }] = useQuery({
    query: QUARTERLY_AUDITS_QUERY(false)
  })

  const quarterlyAudits = () => quarterlyAuditsData?.quarterlyAudits
  const allAuditSources = () =>
    _.uniqBy(quarterlyAuditsData?.quarterlyAudits.map((quarterlyAudit) => quarterlyAudit.auditSources).flat(), "id")

  const [selectedQuarterName, setSelectedQuarterName] = useState(null)

  const searchOptions = _.uniqBy(
    quarterlyAudits()?.map((quarterlyAudit) => ({
      label: quarterlyAudit.name,
      value: quarterlyAudit.name
    })),
    "value"
  )

  const handleFiltered = (filtered) => {
    setSelectedQuarterName(filtered?.value)
  }

  const filteredQuarterlyAudits = () => {
    if (selectedQuarterName) {
      return quarterlyAudits()?.filter((quarterlyAudit) => quarterlyAudit.name === selectedQuarterName)
    } else {
      return []
    }
  }

  const customCellRenderers = {};

  const customHeaderRenderers = {
    imageHeaderGroupRenderer: ImageHeaderRenderer,
  };

  const columnDefs = headerColumns(allAuditSources(), filteredQuarterlyAudits());

  if (!quarterlyAuditsData) {
    return <div>Loading...</div>;
  }


  return (
    <>
    <div className="flex my-6 items-center">
      <p className="font-semibold">
        Select past audit:
      </p>
      <div className="ml-2 min-w-[200px]">
        <Select
          options={searchOptions}
          onChange={handleFiltered}
          menuPortalTarget={document.body}
          styles={selectStyling()}
        />
      </div>
    </div>
    {selectedQuarterName &&
      <AGGridTable
        data={filteredQuarterlyAudits()}
        headerColumns={columnDefs}
        customCellRenderers={customCellRenderers}
        customHeaderRenderers={customHeaderRenderers}
        footerRowStyle={{ backgroundColor: '#c89a2c', color: '#fff' }}
      />
    }
    </>
  )
}
