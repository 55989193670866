import { ResponsiveLine } from "@nivo/line"
import { Select, Label } from "flowbite-react"
import React, { useState, useEffect } from "react"

import UnitsTable from "../../../shared/UnitsTable"

import {
  getAvgAskingRentPsfByDate,
  getAvgAskingRentByDate,
  getAvgConcessionRentPsfByDate,
  getAvgConcessionRentByDate,
  getAvgEffectRentPsfByDate,
  getAvgEffectRentByDate
} from "./HistoricalRentData"
import { UnitCollection } from "./UnitCollection"

const metricToFunctionMap = {
  1: getAvgAskingRentPsfByDate,
  2: getAvgEffectRentPsfByDate,
  3: getAvgConcessionRentPsfByDate,
  4: getAvgAskingRentByDate,
  5: getAvgEffectRentByDate,
  6: getAvgConcessionRentByDate
}

export default function HistoricalRentTrends({
  subject = {},
  comparables = [],
  properties = [],
  filter = { startDate: null, endDate: null }
}) {
  const [lineData, setLineData] = useState(null)
  const { startDate, endDate } = filter

  const [metric, setMetric] = useState(1)
  const [filteredProperties, setFilteredProperties] = useState(null)
  const [subfilter, setSubFilter] = useState({ startDate: startDate, endDate: endDate });

  const handleClicklog = (point) => {
    const from = new Date(point.data.x)
    const to = new Date(point.data.x)

    from.setDate(from.getDate() - 3)
    to.setDate(to.getDate() + 3)

    setSubFilter({ startDate: from, endDate: to })

    const filteredProperties = UnitCollection.filterPropertiesByDateRange(properties, subfilter)
    setFilteredProperties(filteredProperties)
  }

  const handleClearFilter = () => {
    setFilteredProperties(null)
    setSubFilter({ startDate: null, endDate: null })
  }

  useEffect(() => {
    const fetchData = async () => {
      const func = metricToFunctionMap[metric]
      if (func) {
        const data = await func(subject, comparables, startDate, endDate)
        setLineData(data)
      }
    }

    fetchData()
  }, [metric, startDate, endDate, subject, comparables, properties])

  return (
    <>
      {lineData && (
        <div className="mt-8 rounded-md border">
          <div className="flex items-center justify-between p-4">
            <div>
              <p className="mb-1 text-xl font-semibold">Historical Rent Trends</p>
              <p className="text-xs font-medium text-gray-500">
                Track historical min, max and average rent trends in your market.
              </p>
            </div>
            <div className="flex items-center space-x-8">
              <div className="flex items-center">
                <Label className="m-0 mr-2">Metric</Label>
                <Select
                  id="metric"
                  className="min-w-[9rem] font-medium"
                  onChange={(e) => {
                    setMetric(e.target.value)
                  }}>
                  <option value="1">Asking Rent PSF</option>
                  <option value="2">Effective Rent PSF</option>
                  <option value="3">Concession Rent PSF</option>
                  <option value="4">Asking Rent</option>
                  <option value="5">Effective Rent</option>
                  <option value="6">Concession $</option>
                </Select>
              </div>
            </div>
          </div>
          <div className="my-2 border-t border-gray-200 opacity-50"></div>

          <div className="h-80" style={{ width: "100%" }}>
            <ResponsiveLine
              data={lineData}
              margin={{ top: 60, right: 110, bottom: 50, left: 60 }}
              yFormat=" >-$.2r"
              colors={{ scheme: "paired" }}
              xScale={{ type: "point" }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "",
                legendOffset: 36,
                legendPosition: "middle",
                truncateTickAt: 0
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "",
                legendOffset: -40,
                legendPosition: "middle",
                truncateTickAt: 0
              }}
              pointSize={10}
              pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              pointLabel="data.yFormatted"
              enablePointLabel={true}
              enableTouchCrosshair={true}
              useMesh={true}
              onClick={handleClicklog}
              legends={[
                {
                  anchor: "top-right",
                  direction: "row",
                  justify: false,
                  translateX: -100,
                  translateY: -50,
                  itemsSpacing: 2,
                  itemDirection: "left-to-right",
                  itemWidth: 150,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: "circle",
                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemBackground: "rgba(0, 0, 0, .03)",
                        itemOpacity: 1
                      }
                    }
                  ]
                }
              ]}
            />
          </div>

          <div className="ml-3 mr-3">
            <div className="my-2 text-left">
              {filteredProperties ? (
                <>
                  <p className="mb-4 font-bold">
                    Available Units Between {subfilter.startDate?.toLocaleDateString()} and {subfilter.endDate?.toLocaleDateString()}{" "}
                    <button className="text-dark-grey hover:underline" onClick={handleClearFilter}>
                      ✖
                    </button>
                  </p>
                  <UnitsTable properties={filteredProperties} />
                </>
              ) : (
                <p>Click on a point in time on the chart above to see the units that make it up.</p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
