import { ResponsiveBarCanvas } from "@nivo/bar"
import { Label } from "flowbite-react"
import React, { useState, useEffect } from "react"

import UnitsTable from "../../../shared/UnitsTable"

import { UnitCollection } from "./UnitCollection"

export function FutureAvailability({ properties = [] }) {
  const currentDate = new Date()
  const [timeFrame, setTimeFrame] = useState(6)
  const [futurePeriods, setFuturePeriods] = useState(10)
  const [futureData, setFutureData] = useState([])
  const [chartData, setChartData] = useState([])
  const [filteredProperties, setFilteredProperties] = useState(null)
  const [subfilter, setSubFilter] = useState({ startDate: null, endDate: null })

  useEffect(() => {
    initializeData()
  }, [properties])

  const initializeData = () => {
    setFuturePeriods(10)
    setTimeFrame(6)
    const data = propertiesAvailableInFuture(properties)
    setFutureData(data)
  }

  const handleDateRange = (point) => {
    const selectedPeriod = futureData.find(f => f.formatedLabel === point.data.formatedLabel)
    if (!selectedPeriod) return

    const filteredProperties = selectedPeriod[point.id]
    setFilteredProperties(filteredProperties);
    setSubFilter({ startDate: selectedPeriod.from, endDate: selectedPeriod.to })
  }

  const handleClearFilter = () => {
    setFilteredProperties(null)
    setSubFilter({ startDate: null, endDate: null })
  }

  const propertiesAvailableInFuture = (properties, periods = futurePeriods) => {
    const futureProps = UnitCollection
      .fromProperties(properties)
      .units
      .filter(property => new Date(property.availability) >= currentDate)

    const futureAvailabilityData = []
    const periodsData = []

    for (let i = 0; i < periods; i++) {
      const { from, to, formattedDate } = calculatePeriodDates(i)
      const units = futureProps.filter((p) => new Date(p.availability) >= from && new Date(p.availability) <= to)

      futureAvailabilityData.push({
        from,
        to,
        formatedLabel: formattedDate,
        units: units.length,
        "0 BR": units.filter((u) => u.bed === 0),
        "1 BR": units.filter((u) => u.bed === 1),
        "2 BR": units.filter((u) => u.bed === 2),
        "3 BR": units.filter((u) => u.bed === 3),
        "4 BR": units.filter((u) => u.bed === 4)
      })

      periodsData.push({
        "formatedLabel": formattedDate,
        "0 BR": units.filter((u) => u.bed === 0).length,
        "0 BRColor": "hsl(140, 70%, 50%)",
        "1 BR": units.filter((u) => u.bed === 1).length,
        "1 BRColor": "hsl(220, 70%, 50%)",
        "2 BR": units.filter((u) => u.bed === 2).length,
        "2 BRColor": "hsl(129, 70%, 50%)",
        "3 BR": units.filter((u) => u.bed === 3).length,
        "3 BRColor": "hsl(24, 70%, 50%)",
        "4 BR": units.filter((u) => u.bed === 4).length,
        "4 BRColor": "hsl(311, 70%, 50%)"
      })
    }
    setChartData(periodsData)
    return futureAvailabilityData
  }

  const calculatePeriodDates = (periodIndex) => {
    const from = new Date(currentDate)
    from.setDate(from.getDate() + periodIndex * timeFrame)

    const to = new Date(from)
    to.setDate(to.getDate() + timeFrame)

    const formattedDate = `${from.getMonth() + 1}/${from.getDate()} - ${to.getMonth() + 1}/${to.getDate()}`
    return { from, to, formattedDate }
  }

  const datesText = "Next 60 Days"

  return (
    <div className="mt-8 rounded-md border">
      <div className="flex items-center justify-between p-4">
        <div>
          <p className="mb-1 text-xl font-semibold">Future Availability</p>
          <p className="text-xs font-medium text-gray-500">
            Monitor the future supply of availabities across your comps for units that have issued a notice to vacate.
          </p>
        </div>
        <div className="flex items-center space-x-8">
          <div className="flex items-center">
            <Label className="m-0 mr-2">{datesText}</Label>
          </div>
        </div>
      </div>
      <div className="h-80 overflow-x-auto">
        <ResponsiveBarCanvas
          data={chartData}
          keys={[
            "0 BR",
            "1 BR",
            "2 BR",
            "3 BR",
            "4 BR"
          ]}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          indexBy="formatedLabel"
          colors={{ scheme: "paired" }}
          groupMode="stacked"
          borderRadius={4}
          innerPadding="0"
          margin={{ top: 50, right: 0, bottom: 50, left: 50 }}
          onClick={handleDateRange}
          legends={[
            {
              dataFrom: "keys",
              anchor: "top-right",
              direction: "row",
              justify: false,
              translateX: -400,
              translateY: -30,
              itemWidth: 160,
              itemHeight: 20,
              itemsSpacing: 2,
              symbolSize: 20,
              itemDirection: "left-to-right"
            }
          ]}
        />
      </div>

      <div className="ml-3 mr-3">
          <div className="my-2 text-left">
            {filteredProperties ? (
              <>
                <p className="mb-4 font-bold">
                  Available Units Between {subfilter.startDate?.toLocaleDateString()} and {subfilter.endDate?.toLocaleDateString()}{" "}
                  <button className="text-dark-grey hover:underline" onClick={handleClearFilter}>
                    ✖
                  </button>
                </p>
                <UnitsTable properties={filteredProperties} type= {'units'}/>
                </>
            ) : (
              <p>Click on a point in time on the chart above to see the units that make it up.</p>
            )}
          </div>
        </div>
    </div>
  )
}
