import React, { useState, useMemo } from "react";
import { useQuery } from 'urql';
import AGGridTable from '../../shared/AGGridTable';
import { headerColumns } from './ilsConversionsTableConfig';
import { PROPERTY_INSIGHTS_QUERY } from '../../queries/PropertyInsightsQueries';
import Selectbox from "../../shared/Selectbox";

const GET_AVAILABLE_MONTHS_QUERY = `
  query GetAvailablePropertyInsightMonths {
    availablePropertyInsightMonths
  }
`;

const processData = (data) => {
  if (!data?.propertyInsights) return [];
  const groupedInsights = data.propertyInsights.reduce((acc, insight) => {
    if (!acc[insight.property.id]) {
      acc[insight.property.id] = { ...insight.property, propertyInsights: [] };
    }
    acc[insight.property.id].propertyInsights.push(insight);
    return acc;
  }, {});
  return Object.values(groupedInsights);
};

const MonthlyView = () => {
  const [selectedMonth, setSelectedMonth] = useState(() => {
    const date = new Date();
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
  });

  const [{ data, fetching, error }, reexecuteQuery] = useQuery({
    query: PROPERTY_INSIGHTS_QUERY,
    variables: { month: selectedMonth },
  });

  const [{ data: monthsData, fetching: monthsFetching, error: monthsError }] = useQuery({
    query: GET_AVAILABLE_MONTHS_QUERY,
  });

  const monthOptions = monthsData?.availablePropertyInsightMonths?.map((month) => ({
    value: month,
    label: month,
  })) || [];


  const defaultMonthOption = monthOptions.find(option => option.value === selectedMonth) || null;

  const processedData = useMemo(() => processData(data || {}), [data]);
  const columnDefs = useMemo(() => headerColumns(processedData, true), [processedData]);

  if (fetching || monthsFetching) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (monthsError) return <div>Error: {monthsError.message}</div>;

  return (
    <div>
      <div className="mb-4 max-w-[150px]">
        <Selectbox
          options={monthOptions}
          defaultValue={defaultMonthOption}
          onChange={(value) => {
            setSelectedMonth(value);
            reexecuteQuery({ requestPolicy: 'network-only' });
          }}
          id="monthly-view-month-select"
        />
      </div>
      <AGGridTable
        data={processedData}
        headerColumns={columnDefs}
        customCellRenderers={{}}
        customHeaderRenderers={{}}
      />
    </div>
  );
};

export default MonthlyView;
