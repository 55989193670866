import React, { useEffect } from 'react';

const IntercomTracker = () => {
  useEffect(() => {
    const init = () => {
      const trackPageView = () => {
        if (window.Intercom) {
          window.Intercom('trackEvent', 'page_viewed', {
            page: window.location.pathname,
            url: window.location.href,
            title: document.title,
            timestamp: new Date().toISOString()
          });
        }
      };

      trackPageView();

      document.addEventListener('turbolinks:load', trackPageView);
      return () => {
        document.removeEventListener('turbolinks:load', trackPageView);
      };
    };

    if (document.readyState === 'complete') {
      init();
    } else {
      document.addEventListener('DOMContentLoaded', init);
      return () => document.removeEventListener('DOMContentLoaded', init);
    }
  }, []);

  return null;
};

export default IntercomTracker;