import { CheckCircleIcon, PencilIcon, PencilSquareIcon, XCircleIcon } from "@heroicons/react/24/solid"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import styled from "styled-components"

import FloorplansProvider from "../../context/FloorplansContext"
import { GlobalContext } from "../../context/GlobalContext"
import PropertiesProvider from "../../context/PropertiesContext"
import BrynsonTable from "../../shared/BrynsonTable"
import CheckBox from "../../shared/CheckBox"
import CheckBoxExtended from "../../shared/CheckboxExtended"
import FloorplanUpload from "../../shared/FloorplanUpload"
import Loading from "../../shared/Loading"
import PropertySidebar from "../../shared/PropertySidebar"
import Sidebar from "../../shared/Sidebar"

const TableWrapper = styled.div`
  .table th {
    white-space: nowrap;
    font-size: 13px;
  }
`

export default function PropertyDirectory() {
  const [showSidebar, setShowSidebar] = useState(false)
  const [selectedProperty, setSelectedProperty] = useState()
  const [showPropertyDetail, setShowPropertyDetail] = useState(false)
  const [showCreateProperty, setShowCreateProperty] = useState(false)
  const [propertyToDetail, setPropertyToDetail] = useState()
  const [uploadLoading, setUploadLoading] = useState(false)

  const handleUploadLoading = (value) => {
    setUploadLoading(value)
  }

  const { propertiesData: data, propertyReload: reexecuteQuery, updateFeedEnabled, currentUserData } = useContext(GlobalContext)

  const [currentCompany, setCurrentCompany] = useState()

  const company = useMemo(() => currentUserData?.currentUser?.currentCompany, [currentUserData])

  const tableHeader = () => (
    <div>

    </div>
  )

  const feedCell = (name, cell) => {
    const feed = cell.row.original.feeds.find((x) => x.name === name)
    return feed && feed.enabled || (company.about === 'PMC Demo')? (
      <div className="align-center flex justify-center">
        <CheckCircleIcon width={24} height={24} />
      </div>
    ) : (
      <div className="align-center flex justify-center">
        <XCircleIcon className="text-rose-400" width={24} height={24} />
      </div>
    )
  }

  const listingProperties = [
    {
      accessor: "name",
      Header: "Property Name",
      type: "text",
      className: "full-width font-medium min-w-[300px]"
    },
    {
      accessor: "unitCount",
      Header: "Total Units",
      className: "text-center max-w-[100px]",
      type: "text",
      Cell: (props) => (
        <span className="font-medium text-bblue" href={`/portal/units/${props.cell.row.original.id}`}>
          {props.cell.value}
        </span>
      )
    },
    {
      accessor: "units",
      Header: "Syndicated Units",
      className: "text-center min-w-[100px] text-wrap",
      type: "text",
      Cell: (props) => (
        <a className="font-medium text-bblue underline" href={`/portal/units/${props.cell.row.original.id}`}>
          {props.cell.value.length}
        </a>
      )
    },
    {
      accessor: "todoTwo",
      Header: "Non-syndicated Units",
      className: "text-center min-w-[200px] text-wrap",
      type: "text",
      Cell: (props) => (
        <span className="font-medium text-bblue" href={`/portal/units/${props.cell.row.original.id}`}>
          {props.cell.row.original.unitCount - props.cell.row.original.units.length}
        </span>
      )
    },
    {
      accessor: "floorPlanCount",
      Header: "Floor Plans",
      className: "text-center",
      type: "text",
      Cell: (props) => (
        <a className="font-medium text-bblue underline" href={`/portal/floorplans/${props.cell.row.original.id}`}>
          {props.cell.value}
        </a>
      )
    },
    {
      accessor: "apartmentListStatus",
      Header: "Aptlist",
      className: "text-center",
      type: "text",
      Cell: (props) => feedCell("apartmentlist", props.cell)
    },
    {
      accessor: "apartmentsFeedStatus",
      Header: "Apts.com",
      className: "text-center",
      type: "text",
      Cell: (props) => feedCell("apartments_com", props.cell)
    },
    {
      accessor: "dwellsyFeedStatus",
      Header: "Dwellsy",
      className: "text-center",
      type: "text",
      Cell: (props) => feedCell("dwellsy", props.cell)
    },
    {
      accessor: "rentComFeedStatus",
      Header: "Rent.",
      className: "text-center",
      type: "text",
      Cell: (props) => feedCell("rent_com", props.cell)
    },
    {
      Header: "Rentable",
      className: "text-center",
      type: "text",
      Cell: (props) => feedCell("rentable", props.cell)
    },
    {
      accessor: "StreeteasyFeedStatus",
      Header: "Street Easy",
      className: "text-center",
      type: "text",
      Cell: (props) => feedCell("streeteasy", props.cell)
    },
    {
      accessor: "zillowFeedStatus",
      Header: "Zillow",
      className: "text-center",
      type: "text",
      Cell: (props) => feedCell("zillow", props.cell)
    },
    {
      accessor: "zumperFeedStatus",
      Header: "Zumper",
      className: "text-center",
      type: "text",
      Cell: (props) => feedCell("zumper", props.cell)
    },
    {
      accessor: "id",
      Header: "",
      className: "text-center",
      type: "text",
      Cell: (props) => (
        <button
          className="font-medium text-bblue underline"
          onClick={(e) => {
            const property = data?.properties.find((item) => item.id === props.cell.value)
            if (property) {
              e.stopPropagation()
              e.nativeEvent.stopImmediatePropagation()
              setSelectedProperty(property)
              setShowSidebar(true)
            }
          }}>
          <span className="text-bblue underline">Manage</span>
        </button>
      )
    }
  ]

  const getName = (item) => {
    if (item.name === null) return ""
    const name = item.names.find((i) => i[0] === item.name)[1]
    return name
  }

  const rowClick = (property) => {
    setShowPropertyDetail(true)
    setPropertyToDetail(property)
  }

  useMemo(() => {
    if (data && selectedProperty) {
      const property = data?.properties.find((item) => item.id === selectedProperty.id)
      if (property) {
        setSelectedProperty(property)
      }
    }
  }, [data])

  return (
    <div className="p-8">
      <>
        {data?.properties ? (
          <TableWrapper>
            <BrynsonTable
              data={data?.properties || []}
              columns={listingProperties}
              countTitle={data?.properties.length > 1 ? "total properties" : "property"}
              header={tableHeader}
              rowClick={rowClick}
            />
          </TableWrapper>
        ) : (
          <Loading />
        )}
        <PropertiesProvider>
          <PropertySidebar
            propertyToDetail={propertyToDetail}
            showPropertyDetail={showPropertyDetail}
            showCreateProperty={showCreateProperty}
            setShowPropertyDetail={setShowPropertyDetail}
            setShowCreateProperty={setShowCreateProperty}
            setPropertyToDetail={setPropertyToDetail}
            refreshScreen={() => {
              reexecuteQuery()
            }}
          />
        </PropertiesProvider>
        <Sidebar
          visible={showSidebar}
          header={() => (
            <h5 className="grow text-center text-lg font-bold">{selectedProperty && selectedProperty?.name}</h5>
          )}
          backgroundColor="#FFF"
          iconName="close"
          closeSidebar={() => {
            setShowSidebar(false)
          }}>
          {selectedProperty && showSidebar && (
            <>
              <div className="row flex grid grid-cols-2 flex-wrap">
                {selectedProperty?.feeds
                  ?.sort((a, b) => (a.name > b.name ? 1 : -1))
                  ?.map((item) => (
                    <div className="m-2" key={`feed-${item.id}`}>
                      <CheckBoxExtended
                        name={getName(item)}
                        checked={item.enabled}
                        handleClick={(value) => {
                          updateFeedEnabled({
                            feedId: parseInt(item?.id),
                            value: value
                          }).then((result) => {
                            reexecuteQuery()
                          })
                        }}
                      />
                    </div>
                  ))}
              </div>
              {selectedProperty?.feeds
                ?.sort((a, b) => (a.name > b.name ? 1 : -1))
                ?.map((item) => (
                  <div key={`feed-link-${item.id}`}>
                    {item.enabled && (
                      <a
                        target="_blank"
                        className="text-bblue underline"
                        href={`${window.location.protocol}//${window.location.host}/feeds/${item.name}.xml`}
                        rel="noreferrer">
                        {`${window.location.protocol}//${window.location.host}/feeds/${item.name}.xml`}
                      </a>
                    )}
                  </div>
                ))}
            </>
          )}
        </Sidebar>
      </>
    </div>
  )
}
