// quarterlyAuditsTableConfig.js

import React from 'react';

// Import images (ensure these paths are correct in your project)
const apartmentscom = require('../../../../../public/ils/apartmentscomlogo.png');
const apartmentlist = require('../../../../../public/ils/apartment-list-logo.png');
const zillow = require('../../../../../public/ils/Zillow_logo.png');
const rentcom = require('../../../../../public/ils/rent-com-logo.png');
const zumper = require('../../../../../public/ils/zumper-logo.png');
const rentable = require('../../../../../public/ils/rentable-logo@2x.jpg');
const realtor = require('../../../../../public/ils/realtor-logo.png');
const rentsocial = require('../../../../../public/ils/rent-social-logo.png');
const maxleases = require('../../../../../public/ils/max-leases-logo.png');

const ILSImages = {
  CoStar: (
    <img
      src={apartmentscom}
      width="140px"
      className="object-contain ml-auto mr-auto"
      alt="Apartments.com"
    />
  ),
  Aptlist: (
    <img
      src={apartmentlist}
      width="140px"
      className="object-contain ml-auto mr-auto"
      alt="Apartment List"
    />
  ),
  Rent: (
    <img
      src={rentcom}
      width="50px"
      className="object-contain ml-auto mr-auto"
      alt="Rent.com"
    />
  ),
  Zumper: (
    <img
      src={zumper}
      width="120px"
      className="object-contain ml-auto mr-auto"
      alt="Zumper"
    />
  ),
  Zillow: (
    <img
      src={zillow}
      width="70px"
      className="object-contain ml-auto mr-auto"
      alt="Zillow"
    />
  ),
};

export const headerColumns = (auditSources, allData) => {
  console.log(auditSources)
  // Filter audit sources to include only those with relevant data
  const activeAuditSources = auditSources.filter((source) =>
    allData?.some(
      (dataItem) =>
        dataItem.auditMetrics &&
        dataItem.auditMetrics.some((metric) => metric.auditSource.id === source.id)
    )
  );

  const hasOtherProductsData = allData?.some((dataItem) =>
    activeAuditSources.some((source) =>
      dataItem.auditMetrics?.some(
        (metric) =>
          metric.auditSource.id === source.id &&
          metric.otherProducts !== undefined &&
          metric.otherProducts !== null &&
          metric.otherProducts !== ''
      )
    )
  );

  return [
    {
      type: 'string',
      style: { whiteSpace: 'nowrap' },
      title: 'Property',
      accessor: 'property.name',
      pinned: 'left',
      enableValue: false,
    },
    {
      type: 'string',
      title: 'Quarter Name',
      accessor: 'name',
      enableValue: false,
    },
    {
      type: 'string',
      title: 'City',
      accessor: 'property.city',
      enableValue: false,
    },
    {
      type: 'number',
      title: 'Unit Count',
      accessor: 'property.unitCount',
      aggFunc: 'sum',
      enableValue: false,
    },
    {
      type: 'percentage',
      title: 'Current Occupancy',
      accessor: (item) => {
        let value = item.currentOccupancy;
        if (value === null || value === undefined) return null;
        if (typeof value === 'string') {
          value = value.replace('%', '').trim();
        }
        value = parseFloat(value);
        return isNaN(value) ? null : value;
      },
      aggFunc: 'avg',
      enableValue: false,
    },
    {
      type: 'date',
      title: 'Occupancy as of',
      accessor: 'dateOccupancyUpdated',
    },
    {
      type: 'percentage',
      title: 'Occupancy Exposure',
      accessor: (item) => {
        let value = item.occupancyExposure;
        if (value === null || value === undefined) return null;
        if (typeof value === 'string') {
          value = value.replace('%', '').trim();
        }
        value = parseFloat(value);
        return isNaN(value) ? null : value;
      },
      aggFunc: 'avg',
      enableValue: false,
    },
    {
      type: 'number',
      title: 'Current Vacant Units',
      accessor: 'totalVacantUnits',
      aggFunc: 'sum',
      enableValue: false,
    },
    {
      type: 'percentage',
      title: '60+ Days Occupancy Exposure',
      accessor: 'sixtyDaysOccupancyExposure',
      aggFunc: 'avg',
      enableValue: false,
    },
    {
      type: 'string',
      title: 'Ownership Group',
      accessor: 'ownershipGroup',
    },
    {
      type: 'string',
      title: 'Region',
      accessor: 'region',
    },
    {
      type: 'number',
      title: 'Projected Vacant Units',
      accessor: 'projectVacantUnits',
      aggFunc: 'sum',
    },
    {
      type: 'dynamic',
      keys: activeAuditSources,
      title: (key) => ILSImages[key.displayName] || key.displayName,
      columns: [
        {
          type: 'string',
          title: 'Current Package',
          accessor: (item, key) => {
            if (!item || !item.auditMetrics) return null;
            const auditMetric = item.auditMetrics.find(
              (auditMetric) => auditMetric.auditSource.id === key.id
            );
            return auditMetric ? auditMetric.currentPackage : null;
          },
        },
        ...(hasOtherProductsData
          ? [
              {
                type: 'string',
                title: 'Other Products',
                accessor: (item, key) => {
                  if (!item || !item.auditMetrics) return null;
                  const auditMetric = item.auditMetrics.find(
                    (auditMetric) => auditMetric.auditSource.id === key.id
                  );
                  return auditMetric ? auditMetric.otherProducts : null;
                },
              },
            ]
          : []),
        {
          type: 'currency',
          title: 'Current Rate',
          accessor: (item, key) => {
            if (!item || !item.auditMetrics) return null;
            const auditMetric = item.auditMetrics.find(
              (auditMetric) => auditMetric.auditSource.id === key.id
            );
            return auditMetric ? auditMetric.currentSpend : null;
          },
          aggFunc: 'sum',
          enableValue: false,
        },
        {
          type: 'number',
          title: 'Number of Leads (T90)',
          accessor: (item, key) => {
            if (!item || !item.auditMetrics) return null;
            const auditMetric = item.auditMetrics.find(
              (auditMetric) => auditMetric.auditSource.id === key.id
            );
            return auditMetric ? auditMetric.numberOfLeads : null;
          },
          aggFunc: 'sum',
          enableValue: false,
        },
        {
          type: 'currency',
          title: 'Cost per Lead (T90)',
          accessor: (item, key) => {
            if (!item || !item.auditMetrics) return null;
            const auditMetric = item.auditMetrics.find(
              (auditMetric) => auditMetric.auditSource.id === key.id
            );
            return auditMetric ? auditMetric.costPerLead : null;
          },
          aggFunc: 'avg',
          enableValue: false,
        },
        {
          type: 'number',
          title: 'Number of Leases (T90)',
          accessor: (item, key) => {
            if (!item || !item.auditMetrics) return null;
            const auditMetric = item.auditMetrics.find(
              (auditMetric) => auditMetric.auditSource.id === key.id
            );

            if (auditMetric?.noLeasesCrm) {
              return 'No CRM';
            }

            return auditMetric ? auditMetric.numberOfLeases : null;
          },
          aggFunc: 'sum',
          enableValue: false,
        },
        {
          type: 'currency',
          title: 'Cost per Lease (T90)',
          accessor: (item, key) => {
            if (!item || !item.auditMetrics) return null;
            const auditMetric = item.auditMetrics.find(
              (auditMetric) => auditMetric.auditSource.id === key.id
            );
            return auditMetric ? auditMetric.costPerLease : null;
          },
          aggFunc: 'avg',
          enableValue: false,
        },
      ],
    },
    {
      type: 'group',
      title: 'Recommendations',
      columns: activeAuditSources.map((auditSource) => ({
        type: 'string',
        title: auditSource.displayName,
        className: 'min-w-[125px] text-center',
        accessor: (item) => {
          if (!item || !item.auditMetrics) return null;
          const auditMetric = item.auditMetrics.find(
            (auditMetric) => auditMetric.auditSource.id === auditSource.id
          );
          return auditMetric ? auditMetric.recommendation : null;
        },
      })),
      className: 'bg-bgold text-white min-w-[1000px] text-center',
    },
    {
      type: 'string',
      title: 'Notes',
      accessor: 'notes',
      className: 'min-w-[400px]',
    },
  ];
};
