export const PROPERTY_INSIGHTS_QUERY = `
  query PropertyInsights($date: ISO8601Date, $month: String) {
    propertyInsights(date: $date, month: $month) {
      id
      property {
        id
        name
        city
        unitCount
        propertyDirectoryInfo {
          currentCostarPackage
          brynsonCostarRate
          apartmentListPackage
          brynsonApartmentListRate
          currentRentComPackage
          brynsonRentRate
          currentZumperPackage
          brynsonZumperRate
          currentZillowPackage
          brynsonZillowRate
        }
      }
      name
      insight
      adSource
      date
    }
  }
`;