import { InformationCircleIcon } from "@heroicons/react/24/outline"
import { Table } from "flowbite-react"
import React from "react"

import BrynsonTooltip from "../../shared/BrynsonTooltip"

export default function SubfolderSection({ property }) {
  return (
    <div>
      {property.semrushData.some((x) => x.semrushType === "subfolder_organic") && (
        <div className="mt-6 w-full text-black">
          <h2 className="mb-2 text-lg font-semibold">What are your leads searching for?</h2>
          <p className="mb-2 text-base font-semibold text-grey">
            For the top result in the first section, we checked what else your leads are searching for in Google
          </p>
          <p className="mb-4 text-base font-semibold text-grey">
            If anything has high volume and low CPC, it may be worth targeting those in your PPC campaigns.
          </p>
          <Table hoverable={true} className="text-md mt-4 text-black">
            <Table.Head>
              <Table.HeadCell>Keyword</Table.HeadCell>
              <Table.HeadCell>
                <div className="flex items-center">
                  Search Volume{" "}
                  <InformationCircleIcon className="ml-2 h-6 w-6" data-tooltip-id={`volume-${property.id}`} />
                </div>
              </Table.HeadCell>
              <Table.HeadCell>
                <div className="flex items-center">
                  {" "}
                  Competition <InformationCircleIcon className="ml-2 h-6 w-6" data-tooltip-id={property.id} />
                </div>
              </Table.HeadCell>
              <BrynsonTooltip
                id={property.id}
                place="top"
                render={() => (
                  <div className="max-w-[700px]">
                    <p className="text-lg font-medium">
                      "Competition" is Competitive Density. It is the level of competition in Google Ads measured from 0
                      to 1. This number represents how many advertisers compete for this particular keyword in paid
                      search. The closer the score is to 1, the higher the number of advertisers currently bidding on
                      this keyword. Therefore, the harder it would be to stand out in the search results.
                    </p>
                    <p className="text-lg font-medium">
                      <span className="font-semibold"> Above 0.80:</span> These keywords are highly competitive among
                      advertisers and therefore likely send traffic to paid results. You could infer that if a keyword
                      has a high density of advertisers, the search has some intent on making a transaction.{" "}
                    </p>
                    <p className="text-lg font-medium">
                      <span className="font-semibold">From 0.60-0.80:</span> Keywords with an average density of
                      advertisers. You could experiment with advertising on these keywords if your organic efforts don’t
                      work out, but it won’t be a quick win on any of these keywords, either.
                    </p>
                    <p className="text-lg font-medium">
                      <span className="font-semibold">Below 0.60:</span> Keywords with the lowest density of
                      advertisers. This could either mean that they are under the radar of most competitors that
                      advertise in your niche, or they are simply not profitable to advertise on..
                    </p>
                  </div>
                )}
              />
              <BrynsonTooltip
                id={`volume-${property.id}`}
                place="top"
                render={() => (
                  <div className="max-w-[700px]">
                    <p className="text-lg font-medium">
                      Search volume is the <span className="font-semibold">average number</span> of times a specific
                      search query is entered on Google per month.
                    </p>
                  </div>
                )}
              />
            </Table.Head>
            <Table.Body className="divide-y">
              {property.semrushData
                .filter((x) => x.semrushType === "subfolder_organic")
                .flatMap((x) => x.data)
                .map((row, rowIndex) => (
                  <Table.Row key={rowIndex} data={row}>
                    <Table.Cell>{row.keyword}</Table.Cell>
                    <Table.Cell>{row.search_volume}</Table.Cell>
                    <Table.Cell>{row.competition}</Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </div>
      )}
    </div>
  )
}
