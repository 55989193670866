import React from 'react';

// Import images (ensure these paths are correct in your project)
const apartmentscom = require('../../../../../public/ils/apartmentscomlogo.png');
const apartmentlist = require('../../../../../public/ils/apartment-list-logo.png');
const zillow = require('../../../../../public/ils/Zillow_logo.png');
const rentcom = require('../../../../../public/ils/rent-com-logo.png');
const zumper = require('../../../../../public/ils/zumper-logo.png');

const ILSImages = {
  CoStar: (
    <img
      src={apartmentscom}
      width="140px"
      className="object-contain ml-auto mr-auto"
      alt="Apartments.com"
    />
  ),
  'Apartment List': (
    <img
      src={apartmentlist}
      width="140px"
      className="object-contain ml-auto mr-auto"
      alt="Apartment List"
    />
  ),
  'Rent.com': (
    <img
      src={rentcom}
      width="50px"
      className="object-contain ml-auto mr-auto"
      alt="Rent.com"
    />
  ),
  Zumper: (
    <img
      src={zumper}
      width="120px"
      className="object-contain ml-auto mr-auto"
      alt="Zumper"
    />
  ),
  Zillow: (
    <img
      src={zillow}
      width="70px"
      className="object-contain ml-auto mr-auto"
      alt="Zillow"
    />
  ),
};

const ILSFields = {
  CoStar: {
    package: 'currentCostarPackage',
    rate: 'brynsonCostarRate'
  },
  'Apartment List': {
    package: 'apartmentListPackage',
    rate: 'brynsonApartmentListRate'
  },
  'Rent.com': {
    package: 'currentRentComPackage',
    rate: 'brynsonRentRate'
  },
  Zumper: {
    package: 'currentZumperPackage',
    rate: 'brynsonZumperRate'
  },
  Zillow: {
    package: 'currentZillowPackage',
    rate: 'brynsonZillowRate'
  }
};

const getInsightValue = (propertyInsights, name, adSource) => {
  const insight = propertyInsights.find(i => i.name === name && i.adSource === adSource);
  return insight ? parseInt(insight.insight) : 0;
};

const hasPackageData = (data, adSource) => {
  return data.some(item =>
    item.propertyDirectoryInfo &&
    item.propertyDirectoryInfo[ILSFields[adSource].package]
  );
};


export const headerColumns = (data, isMonthly = false) => {
  const allAdSources = ['Zillow', 'Zumper', 'Apartment List', 'CoStar', 'Rent.com'];
  const activeAdSources = allAdSources.filter(source => hasPackageData(data, source));

  return [
    {
      type: 'string',
      title: 'Property',
      accessor: 'name',
      pinned: 'left',
      enableValue: false,
    },
    {
      type: 'string',
      title: 'City',
      accessor: 'city',
      enableValue: false,
    },
    ...activeAdSources.map(source => {
      const columns = [
        {
          type: 'string',
          title: 'Package',
          accessor: (item) => item.propertyDirectoryInfo && item.propertyDirectoryInfo[ILSFields[source].package],
          enableValue: false,
        },
        {
          type: 'currency',
          title: 'Rate',
          accessor: (item) => {
            const rate = item.propertyDirectoryInfo && item.propertyDirectoryInfo[ILSFields[source].rate];
            return rate ? parseFloat(rate) : null;
          },
          enableValue: false,
        },
        {
          type: 'number',
          title: 'Leads',
          accessor: (item) => getInsightValue(item.propertyInsights, 'Leads by Ad Source (CM)', source),
          enableValue: false,
        },
        {
          type: 'number',
          title: 'Applications',
          accessor: (item) => getInsightValue(item.propertyInsights, 'Application Count by Ad Source (CM)', source),
          enableValue: false,
        },
        {
          type: 'number',
          title: 'Move Ins',
          accessor: (item) => getInsightValue(item.propertyInsights, 'Move Ins by Ad Source', source),
          enableValue: false,
        },
      ];

      if (isMonthly) {
        columns.push(
          {
            type: 'currency',
            title: 'Cost per Lead',
            accessor: (item) => {
              const rate = parseFloat(item.propertyDirectoryInfo?.[ILSFields[source].rate]) || 0;
              const leads = getInsightValue(item.propertyInsights, 'Leads by Ad Source (CM)', source) || 0;
              return leads > 0 ? rate / leads : null;
            },
            enableValue: false,
          },
          {
            type: 'currency',
            title: 'Cost per Application',
            accessor: (item) => {
              const rate = parseFloat(item.propertyDirectoryInfo?.[ILSFields[source].rate]) || 0;
              const applications = getInsightValue(item.propertyInsights, 'Application Count by Ad Source (CM)', source) || 0;
              return applications > 0 ? rate / applications : null;
            },
            enableValue: false,
          },
          {
            type: 'currency',
            title: 'Cost per Move In',
            accessor: (item) => {
              const rate = parseFloat(item.propertyDirectoryInfo?.[ILSFields[source].rate]) || 0;
              const moveIns = getInsightValue(item.propertyInsights, 'Move Ins by Ad Source', source) || 0;
              return moveIns > 0 ? rate / moveIns : null;
            },
            enableValue: false,
          },
          {
            type: 'percentage',
            title: 'Lead to Application Conversion',
            accessor: (item) => {
              const leads = getInsightValue(item.propertyInsights, 'Leads by Ad Source (CM)', source) || 0;
              const applications = getInsightValue(item.propertyInsights, 'Application Count by Ad Source (CM)', source) || 0;
              return leads > 0 ? applications / leads : null;
            },
            enableValue: false,
          },
          {
            type: 'percentage',
            title: 'Application to Move-In Conversion',
            accessor: (item) => {
              const applications = getInsightValue(item.propertyInsights, 'Application Count by Ad Source (CM)', source) || 0;
              const moveIns = getInsightValue(item.propertyInsights, 'Move Ins by Ad Source', source) || 0;
              return applications > 0 ? moveIns / applications : null;
            },
            enableValue: false,
          },
          {
            type: 'percentage',
            title: 'Lead to Move In Conversion',
            accessor: (item) => {
              const leads = getInsightValue(item.propertyInsights, 'Leads by Ad Source (CM)', source) || 0;
              const moveIns = getInsightValue(item.propertyInsights, 'Move Ins by Ad Source', source) || 0;
              return leads > 0 ? moveIns / leads : null;
            },
            enableValue: false,
          },
        );
      }

      return {
        type: 'group',
        title: ILSImages[source] || source,
        columns: columns,
      };
    }),
  ];
};



