export const INCIDENT_REPORT_INCIDENTS_QUERY = `
  query {
    incidentReportIncidents {
      id
      criticalIssueType
      description
      incidentStatus
      status
      incidentStatus
      daysSinceIncident
      property {
        id
        name
      }
    }
  }
`

export const INCIDENT_REPORT_DATA_QUERY = `
  query {
    incidentReportData {
      id
      portfolio
      address
      state
      daysSinceIncident
      incidentDatetime
      incidentDatetimeReportedToManegement
      description
      critical
      criticalIssueType
      elevatorDescription
      majorPlumbingDescription
      roofDescription
      accessGatesDescription
      amenityDescription
      fireSystemDescription
      lifeSafetyDescription
      actionsTaken
      nextSteps
      pointOfContact
      details
      personCompletingTheIncidentReport
      incidentStatus
      arrivalTime
      requireInsurance
      resolutionDetails
      resolvedAt
      resolved
      property {
        id
        name
      }
    }
  }
`

export const INCIDENT_REPORT_STATISTICS_QUERY = `
  query {
    incidentReportStatistics {
      state
      pendingCriticalIncidents
      pendingTotalIncidents
    }
  }
`;
