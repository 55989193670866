import React, { useImperativeHandle, forwardRef, useState } from "react"
import InputField from "../../shared/InputField"
import SelectField from "../../shared/SelectField"
import BrynsonDatePicker from "../../shared/BrynsonDatePicker"

const ilsTypeOptions = ["CoStar", "Zillow", "Rent.com", "ApartmentList", "Zumper"].map(value => ({ value, label: value }))
const rateTypeOptions = ["Standard", "Value", "Brynson"].map(value => ({ value, label: value }))

const RateForm = forwardRef(({ initialRate = {} }, ref) => {
  const [currentInput, setCurrentInput] = useState(initialRate)

  const addValue = (field, value) => {
    setCurrentInput(prev => ({ ...prev, [field]: value }))
  }

  useImperativeHandle(ref, () => ({
    getFormData: () => currentInput
  }))

  return (
    <div className="mt-4 pb-12 px-4">
      <div className="mb-2 w-full">
        <label htmlFor="ils-type">ILS Type</label>
        <SelectField
          id="ils-type"
          placeholder="Select ILS Type"
          options={ilsTypeOptions}
          defaultValue={{ label: initialRate.ilsType, value: initialRate.ilsType }}
          onChange={(value) => addValue("ilsType", value)}
        />
      </div>

      <div className="mb-2 w-full">
        <label htmlFor="rate-type">Rate Type</label>
        <SelectField
          id="rate-type"
          placeholder="Select Rate Type"
          options={rateTypeOptions}
          defaultValue={{ label: initialRate.rateType, value: initialRate.rateType }}
          onChange={(value) => addValue("rateType", value)}
        />
      </div>

      <div className="mb-2 w-full">
        <label htmlFor="effective-from">Effective From</label>
        <BrynsonDatePicker
          selectedDate={initialRate.effectiveFrom ? new Date(initialRate.effectiveFrom) : null}
          onChange={(date) => addValue("effectiveFrom", date)}
        />
      </div>

      <div className="mb-2 w-full">
        <label htmlFor="effective-to">Effective To</label>
        <BrynsonDatePicker
          selectedDate={initialRate.effectiveTo ? new Date(initialRate.effectiveTo) : null}
          onChange={(date) => addValue("effectiveTo", date)}
        />
      </div>

      <div className="mb-2 w-full">
        <label htmlFor="min-units">Minimum Units</label>
        <InputField
          id="min-units"
          placeholder="Minimum Units"
          defaultValue={initialRate.minUnits}
          type="number"
          onChange={(e) => addValue("minUnits", parseInt(e.target.value))}
        />
      </div>

      <div className="mb-2 w-full">
        <label htmlFor="max-units">Maximum Units</label>
        <InputField
          id="max-units"
          placeholder="Maximum Units"
          defaultValue={initialRate.maxUnits}
          type="number"
          onChange={(e) => addValue("maxUnits", parseInt(e.target.value))}
        />
      </div>

      <div className="mb-2 w-full">
        <label htmlFor="contract-term">Contract Term (Months)</label>
        <InputField
          id="contract-term"
          placeholder="Contract Term"
          defaultValue={initialRate.contractTermMonths}
          type="number"
          onChange={(e) => addValue("contractTermMonths", parseInt(e.target.value))}
        />
      </div>

      <div className="mb-2 w-full">
        <label htmlFor="package-name">Package Name</label>
        <InputField
          id="package-name"
          placeholder="Package Name"
          defaultValue={initialRate.packageName}
          type="text"
          onChange={(e) => addValue("packageName", e.target.value)}
        />
      </div>

      <div className="mb-2 w-full">
        <label htmlFor="rate">Rate</label>
        <InputField
          id="rate"
          placeholder="Rate"
          defaultValue={initialRate.rate}
          type="number"
          step="0.01"
          onChange={(e) => addValue("rate", parseFloat(e.target.value))}
        />
      </div>
    </div>
  )
})

export default RateForm