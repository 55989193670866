import { Table, Tooltip } from "flowbite-react"
import React from "react"

export function FinancialAnalysis({ subject_estimate = {}, properties= []}) {
  return (
    <div className="mb-8 overflow-hidden rounded-md border">
      {/* Header Section */}
      <div className="flex items-center justify-between p-4">
        <div>
          <p className="mb-1 text-xl font-semibold">Financial Analysis</p>
          <p className="text-xs font-medium text-gray-500">
            Our estimate of the subject property's stabilized income and operating expenses over the next year, based on operating metrics from similar properties.
          </p>
        </div>
      </div>

      <FinancialAnalysisTable subject_estimate={subject_estimate} properties={properties} />

      {/* Footer */}
      <div className="flex items-center justify-between p-4">
        <div>
          <p className="text-xs font-medium text-gray-500">
            Computed based on operating expense data from similar multifamily properties in your market, controlling for differences in size, age, quality, amenities and location.
          </p>
        </div>
      </div>
    </div>
  )
}

function FinancialAnalysisTable({ subject_estimate = {}, properties }) {
  const rows = [
    { label: "Gross Potential Rent (GPR)", key: "gross_potential_rent" },
    { label: "Vacancy Loss", key: "vacancy_loss" },
    { label: "Other Income", key: "other_income" },
    { label: "Parking Income", key: "parking_income" },
    { label: "Effective Gross Income (EGI)", key: "egi", isTotal: true },
  ];

  const expenseRows = [
    { label: "Real Estate Taxes", key: "real_estate_taxes" },
    { label: "Property Insurance", key: "property_insurance" },
    { label: "Utilities", key: "utilities" },
    { label: "Repair and Maintenance", key: "repair_and_maintenance" },
    { label: "Management Fees", key: "management_fees" },
    { label: "Payroll and Benefits", key: "payroll_and_benefits" },
    { label: "Marketing", key: "advertising_and_marketing" },
    { label: "Professional Fees", key: "professional_fees" },
    { label: "General and Administrative", key: "general_and_administrative" },
    { label: "Other Expenses", key: "other_expenses" },
    { label: "Total Operating Expenses", key: "total_operating_expenses", isTotal: true },
  ];

  const noiRow = { label: "Net Operating Income (NOI)", key: "noi", isTotal: true };

  const average_gpr = subject_estimate?.gross_potential_rent?.predicted;
  const units = properties[0]?.number_units;

  const property_info = {
    average_gpr: average_gpr,
    units: units,
    year_built: properties[0]?.year_built,
    gpr_per_unit: Math.ceil(average_gpr / units),
    building_quality: properties[0]?.building_quality?.property_overall_quality?.toFixed(2) * 10,
  }

  return (
    <Table>
      <Table.Head className="bg-gray-50">
        <Table.HeadCell className="text-left">Income</Table.HeadCell>
        <Table.HeadCell className="text-right">Subject Property Total</Table.HeadCell>
        <Table.HeadCell className="text-right">Per Unit</Table.HeadCell>
        <Table.HeadCell className="text-right">% Of GPR</Table.HeadCell>
      </Table.Head>
      <Table.Body className="border-t text-sm">
        {rows.map((row) => (
          <TableRow key={row.key} label={row.label} value={subject_estimate[row.key]} isTotal={row.isTotal} property_info={property_info} />
        ))}

        {/* Expenses */}
        <Table.Row className="bg-gray-50">
          <Table.Cell className="text-left justify-start" colSpan={3} >Operating Expenses</Table.Cell>
          <Table.Cell className="text-right justify-end">% Of EGI</Table.Cell>
        </Table.Row>

        {expenseRows.map((row) => (
          <TableRow key={row.key} label={row.label} value={subject_estimate[row.key]} isTotal={row.isTotal} property_info={property_info} />
        ))}

        {/* NOI */}
        <TableRow label="NOI" isHeader />
        <TableRow label={noiRow.label} value={subject_estimate[noiRow.key]} isTotal={noiRow.isTotal} property_info={property_info} />
      </Table.Body>
    </Table>
  );
}

function TableRow({ label, value, isHeader, isTotal, property_info }) {
  return (
    <Table.Row className={`border-t ${isTotal ? "font-semibold" : ""} ${isHeader ? "bg-gray-50" : "text-gray-600"}`}>
      <Table.Cell className="px-6 py-2 text-left align-middle">{label}</Table.Cell>
      {isHeader ? (
        <Table.Cell colSpan={3} />
      ) : (
        <>
          <Table.Cell className="px-6 text-right align-middle flex justify-end">
            <Tooltip
              content={getToolTipContent(label, value, property_info)}
              style="dark"
              placement="left"
            >
              ${value?.predicted || "000"}
            </Tooltip>
          </Table.Cell>
          <Table.Cell className="px-6 text-right align-middle">
            ${Math.ceil(value?.predicted / property_info.units)}
          </Table.Cell>
          <Table.Cell className="px-6 text-right align-middle">
            {((value?.predicted / property_info.average_gpr) * 100).toFixed(1)}%
          </Table.Cell>
        </>
      )}
    </Table.Row>
  );
}

const getToolTipContent = (label, value, property_info) => {
  const renderInfoRow = (left, center, right = "N/A") => (
    <div className="flex justify-between mb-2 mx-3">
      <span className="text-left flex-1">{left}</span>
      <span className="text-right flex-1">{center}</span>
      <span className="text-right flex-1">{right}</span>
    </div>
  )

  const renderHeaderRow = (texts) => (
    <div className="flex justify-between mx-3 bg-gray-500">
      {texts.map((text) => (
        <span key={text} className="text-center">{text}</span>
      ))}
    </div>
  )

  return (
    <div className="w-80 text-sm">
      <p className="text-center font-semibold mb-2">{label}</p>

      {renderHeaderRow(["Lower", "Average", "Upper"])}
      <div className="flex justify-between mb-2 mx-3">
        <span className="text-left">${value?.lower ?? "N/A"}</span>
        <span className="text-center">${value?.predicted ?? "N/A"}</span>
        <span className="text-right">${value?.upper ?? "N/A"}</span>
      </div>

      <p className="font-semibold text-center">Most Important Factors</p>

      {renderHeaderRow(["Dimension", "Value", "Impact"])}
      {renderInfoRow("Year Built", property_info.year_built)}
      {renderInfoRow("Number Units", property_info.units)}
      {renderInfoRow("GPR Per Unit", `$${property_info.gpr_per_unit}`)}
      {renderInfoRow("Building Quality", `${property_info.building_quality}/10`)}
    </div>
  )
}
