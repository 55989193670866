import React from 'react';
import { ExportToCsv } from 'export-to-csv';

export const exportCSV = (data, filenamePrefix) => {
  if (!data || data.length === 0) {
    console.error("No data available to export.");
    return;
  }

  const columnKeys = Object.keys(data[0]);
  const firstColumnName = columnKeys.length > 0 ? columnKeys[0] : "";
  const secondColumnName = columnKeys.length > 1 ? columnKeys[1] : "";

  const filename = `${filenamePrefix} - ${firstColumnName} ${secondColumnName}`;

  const csvExporter = new ExportToCsv({
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    title: 'Data Export',
    filename: filename,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  });

  csvExporter.generateCsv(data);
};

export const transformDataForChart = (data, chartType) => {
  try {
    switch (chartType) {
      case 'bar':
      case 'line':
        return data.map(entry => ({
          index: entry[Object.keys(entry)[0]],
          ...Object.keys(entry).slice(1).reduce((acc, key) => ({
            ...acc,
            [key]: parseFloat(entry[key]) || 0
          }), {})
        }));
      case 'pie':
        return data.map(entry => ({
          id: entry[Object.keys(entry)[0]],
          value: parseFloat(entry[Object.keys(entry)[1]]) || 0,
        }));
      case 'radar':
        const keys = Object.keys(data[0]).slice(1);
        return keys.map(key => ({
          id: key,
          data: data.map(d => ({
            axis: d[Object.keys(d)[0]],
            value: parseFloat(d[key]) || 0,
          }))
        }));
      default:
        return data;
    }
  } catch (error) {
    console.error("Error transforming data for chart:", error);
    return null;
  }
};


// Utility function to check if a value is numeric
const isNumeric = (value) => {
  if (typeof value === 'number') return true;
  if (typeof value !== 'string') return false;
  return !isNaN(value) && !isNaN(parseFloat(value));
};

// Utility function to format string values
export const formatStringValue = (value) => {
  if (typeof value !== 'string') return value;

  return value
    .replace(/\bCostar\b/g, 'CoStar')
    .replace(/\bAptlist\b/g, 'Apartment List')
    .replace(/\bIls\b/gi, 'ILS');
};

// Utility function to format numeric values
export const formatNumericValue = (value, fieldName) => {
  // If the value is null or undefined, return a dash
  if (value === null || value === undefined) {
    return '-';
  }

  // If the value is not numeric, return it formatted as a string
  if (!isNumeric(value)) {
    return formatStringValue(value);
  }

  // Parse the value to a float
  const numValue = parseFloat(value);

  // Check if it's likely to be a percentage
  if (fieldName.toLowerCase().includes('percentage') || (numValue >= 0 && numValue <= 1 && !fieldName.toLowerCase().includes('spend'))) {
    return (numValue * 100).toFixed(2) + '%';
  }

  // Check if it's likely to be currency
  if (fieldName.toLowerCase().includes('spend') || fieldName.toLowerCase().includes('revenue')) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(numValue);
  }

  // Check if it's likely to be a count (e.g., number of leads)
  if (fieldName.toLowerCase().includes('count') ||
      fieldName.toLowerCase().includes('number') ||
      fieldName.toLowerCase().includes('leads') ||
      Number.isInteger(numValue)) {
    return numValue.toLocaleString(); // Format as integer with thousand separators
  }

  // For other numbers, return with two decimal places
  return numValue.toFixed(2);
};

// Utility function to recursively format all values in an object
export const formatAllValues = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(formatAllValues);
  } else if (typeof obj === 'object' && obj !== null) {
    const formattedObj = {};
    for (const [key, value] of Object.entries(obj)) {
      formattedObj[formatStringValue(key)] = formatNumericValue(value, key);
    }
    return formattedObj;
  }
  return formatNumericValue(obj, '');
};

// Utility function to format table headers
export const formatTableHeaders = (headers) => {
  return headers.map(formatStringValue);
};