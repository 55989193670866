import React, { useRef, useState, useContext } from "react"
import { useMutation } from 'urql'
import RateForm from "./RateForm"
import BrynsonSquareButton from "../../shared/BrynsonSquareButton"
import DesktopH5 from "../../shared/DesktopH5"
import Modal from "../../shared/Modal"
import { GlobalContext } from "../../context/GlobalContext"

const CREATE_RATE_MUTATION = `
  mutation CreateRateMutation(
    $companyId: ID!,
    $ilsType: String!,
    $rateType: String!,
    $effectiveFrom: ISO8601Date!,
    $effectiveTo: ISO8601Date!,
    $minUnits: Int,
    $maxUnits: Int,
    $contractTermMonths: Int,
    $packageName: String!,
    $rate: Float!
  ) {
    createRateMutation(
      companyId: $companyId,
      ilsType: $ilsType,
      rateType: $rateType,
      effectiveFrom: $effectiveFrom,
      effectiveTo: $effectiveTo,
      minUnits: $minUnits,
      maxUnits: $maxUnits,
      contractTermMonths: $contractTermMonths,
      packageName: $packageName,
      rate: $rate
    ) {
      id
      ilsType
      rateType
      effectiveFrom
      effectiveTo
      minUnits
      maxUnits
      contractTermMonths
      packageName
      rate
    }
  }
`

const UPDATE_RATE_MUTATION = `
  mutation UpdateRateMutation(
    $id: ID!,
    $companyId: ID!,
    $ilsType: String!,
    $rateType: String!,
    $effectiveFrom: ISO8601Date!,
    $effectiveTo: ISO8601Date!,
    $minUnits: Int,
    $maxUnits: Int,
    $contractTermMonths: Int,
    $packageName: String!,
    $rate: Float!
  ) {
    updateRateMutation(
      id: $id,
      companyId: $companyId,
      ilsType: $ilsType,
      rateType: $rateType,
      effectiveFrom: $effectiveFrom,
      effectiveTo: $effectiveTo,
      minUnits: $minUnits,
      maxUnits: $maxUnits,
      contractTermMonths: $contractTermMonths,
      packageName: $packageName,
      rate: $rate
    ) {
      id
      ilsType
      rateType
      effectiveFrom
      effectiveTo
      minUnits
      maxUnits
      contractTermMonths
      packageName
      rate
    }
  }
`;


export default function CreateRateModal({ showCreateRate, close, rateToEdit }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const rateFormRef = useRef(null);
  const { currentUserData, rateReload } = useContext(GlobalContext);
  const [, createRate] = useMutation(CREATE_RATE_MUTATION);
  const [, updateRate] = useMutation(UPDATE_RATE_MUTATION);

  const isEditing = Boolean(rateToEdit);

  const handleSave = async () => {
    if (rateFormRef.current) {
      setLoading(true);
      setError(null);

      const formData = rateFormRef.current.getFormData();
      const payload = {
        ...formData,
        companyId: parseInt(currentUserData?.currentUser?.currentCompany?.id)
      };

      try {
        const result = isEditing
          ? await updateRate({ ...payload, id: rateToEdit.id }) // Update rate if editing
          : await createRate(payload); // Otherwise create a new rate

        setLoading(false);

        if (result.error) {
          setError(`Error ${isEditing ? "updating" : "creating"} rate: ${result.error.message}`);
        } else {
          rateReload();
          close(true);
        }
      } catch (e) {
        setLoading(false);
        setError(`Error ${isEditing ? "updating" : "creating"} rate: ${e.message}`);
      }
    }
  };

  return (
    <Modal
      open={showCreateRate}
      closeModal={() => close()}
      header={
        <>
          <DesktopH5 content={isEditing ? "Edit Rate" : "Add new rate"} />
          <p className="text-base text-grey">All fields are required</p>
        </>
      }
      footer={
        <>
          <BrynsonSquareButton
            type="tertiary"
            onClick={() => close()}
            label="Go back"
          />
          <BrynsonSquareButton
            type="primary"
            testId="add-new-rate-button"
            loading={loading}
            onClick={handleSave}
            label={isEditing ? "Update rate" : "Add new rate"}
          />
        </>
      }
    >
      {error && <p className="text-red-600 mb-4">{error}</p>}
      <RateForm ref={rateFormRef} initialRate={rateToEdit || {}} /> {/* Pass initialRate for editing */}
    </Modal>
  );
}
