import React, {useContext, useState, useEffect} from "react"
import AGGridTable from '../../shared/AGGridTable'
import { GlobalContext } from "../../context/GlobalContext"
import Loading from "../../shared/Loading"
import styled from "styled-components"
import USAMap from '../../shared/USAMap';

export default function Incidents() {
	const {
		incidentReportIncidentsData: incidentsData,
		incidentReportData: incidentsDataTab,
		incidentReportStatisticsData: incidentsStatistics,
 	} = useContext(GlobalContext)

	const [complete, setComplete] = useState()
	const [pending, setPending] = useState()

	useEffect(() => {
		if (incidentsData) {
			const complete = incidentsData.incidentReportIncidents.filter(x => x.status === 'complete')
			const pending = incidentsData.incidentReportIncidents.filter(x => x.status === 'pending')
			setComplete(complete)
			setPending(pending)
		}
	}, [incidentsData])

	const columns = [
		{
			accessor: "property.name",
			title: "Property",
			type: "text",
		},
		{
			accessor: "daysSinceIncident",
			title: "Days since incident",
			type: "text",
		},
		{
			accessor: "criticalIssueType",
			title: "Critial Issue Type",
			type: "text",
		},
		{
			accessor: "incidentStatus",
			title: "Incident Status",
			type: "text",
			minWidth: 300
		},
		{
			accessor: "description",
			title: "Description",
			type: "text",
			minWidth: 500
		},
	]

	const dataTabColumns = [
	  {
	    accessor: "portfolio",
	    title: "Portfolio",
	    type: "string",
	  },
	  {
	    accessor: "property.name",
	    title: "Property",
	    type: "string",
	  },
	  {
	    accessor: "address",
	    title: "Address",
	    type: "string",
	  },
	  {
	    accessor: "state",
	    title: "State",
	    type: "string",
	  },
	  {
	    accessor: "daysSinceIncident",
	    title: "Days Since Incident",
	    type: "number",
	  },
	  {
	    accessor: "incidentDatetime",
	    title: "Incident Date",
	    type: "date",
	  },
	  {
	    accessor: "incidentDatetimeReportedToManegement",
	    title: "Incident Date Reported To Management",
	    type: "date",
	  },
	  {
	    accessor: "description",
	    title: "Description",
	    type: "string",
	  },
	  {
	    accessor: "critical",
	    title: "Critical",
	    type: "boolean",
	  },
	  {
	    accessor: "criticalIssueType",
	    title: "Critical Issue Type",
	    type: "string",
	  },
	  {
	    accessor: "elevatorDescription",
	    title: "Elevator Description",
	    type: "string",
	  },
	  {
	    accessor: "majorPlumbingDescription",
	    title: "Major Plumbing Description",
	    type: "string",
	  },
	  {
	    accessor: "roofDescription",
	    title: "Roof Description",
	    type: "string",
	  },
	  {
	    accessor: "accessGatesDescription",
	    title: "Access Gates Description",
	    type: "string",
	  },
	  {
	    accessor: "amenityDescription",
	    title: "Amenity Description",
	    type: "string",
	  },
	  {
	    accessor: "fireSystemDescription",
	    title: "Fire System Description",
	    type: "string",
	  },
	  {
	    accessor: "lifeSafetyDescription",
	    title: "Life Safety Description",
	    type: "string",
	  },
	  {
	    accessor: "actionsTaken",
	    title: "Actions Taken",
	    type: "string",
	  },
	  {
	    accessor: "nextSteps",
	    title: "Next Steps",
	    type: "string",
	  },
	  {
	    accessor: "pointOfContact",
	    title: "Point Of Contact",
	    type: "string",
	  },
	  {
	    accessor: "details",
	    title: "Details",
	    type: "string",
	  },
	  {
	    accessor: "personCompletingTheIncidentReport",
	    title: "Person Completing The Incident Report",
	    type: "string",
	  },
	  {
	    accessor: "incidentStatus",
	    title: "Incident Status",
	    type: "string",
	  },
	  {
	    accessor: "arrivalTime",
	    title: "Arrival Time",
	    type: "string",
	  },
	  {
	    accessor: "requireInsurance",
	    title: "Require Insurance",
	    type: "string",
	  },
	  {
	    accessor: "resolutionDetails",
	    title: "Resolution Details",
	    type: "string",
	  },
	  {
	    accessor: "resolvedAt",
	    title: "Resolved At",
	    type: "date",
	  },
	  {
	    accessor: "resolved",
	    title: "Resolved",
	    type: "boolean",
	  },
	];

	const statsColumns = [
		{
			accessor: "state",
			title: "State",
			type: "text",
		},
		{
			accessor: "pendingCriticalIncidents",
			title: "# Pending Critical Incidents",
			type: "number",
		},
		{
			accessor: "pendingTotalIncidents",
			title: "# Pending Total Incidents",
			type: "number",
		},
	]

	const tableHeader = () => (
    <div></div>
  )

	return (
		<div className="w-full p-8">
			<>
				{ pending && complete && incidentsDataTab ? (
					<div>
						<h1 className="font-semibold text-xl">Olympus Sample Critical Infrastructure Report</h1>
						<div className="w-full bg-white rounded-lg my-4">
							<h2 className="text-md font-semibold mb-4">Pending Incidents by Location</h2>
							<div className="flex flex-row justify-between">
								<div className="w-[600px] h-[600px]">
									<AGGridTable
										data={incidentsStatistics.incidentReportStatistics}
										headerColumns={statsColumns}
										customCellRenderers={{}}
	        					customHeaderRenderers={{}}
	        					height="450px"
									/>
								</div>
								<div className="w-[600px] h-[600px]">
								  <USAMap data={incidentsStatistics.incidentReportStatistics} />
								</div>
							</div>
						</div>
						<div className="w-full bg-white rounded-lg my-8">
							<h2 className="text-md font-semibold mb-4">Pending</h2>

								<AGGridTable
									data={pending}
									headerColumns={columns}
									customCellRenderers={{}}
        					customHeaderRenderers={{}}
        					height="350px"
								/>

						</div>
						<div className="w-full bg-white rounded-lg my-8">
							<h2 className="text-md font-semibold mb-4">Complete</h2>

								<AGGridTable
									data={complete}
									headerColumns={columns}
									customCellRenderers={{}}
        					customHeaderRenderers={{}}
								/>

						</div>

						<div className="w-full bg-white my-8">
							<h2 className="text-md font-semibold mb-4">Data</h2>
								<AGGridTable
									data={incidentsDataTab.incidentReportData}
									headerColumns={dataTabColumns}
									customCellRenderers={{}}
        					customHeaderRenderers={{}}
								/>
						</div>
					</div>

				) : (
					<Loading></Loading>
				)}
			</>
		</div>
	)
}