import { InformationCircleIcon } from "@heroicons/react/24/outline"
import { Table } from "flowbite-react"
import React from "react"

import BrynsonTooltip from "../../shared/BrynsonTooltip"

export default function PaidPhrasesSection({ property }) {
  return (
    <div>
      {property.semrushData.filter((x) => x.semrushType === "phrase_paid").flatMap((x) => x.data).length > 0 && (
        <div className="mt-6 w-full text-black">
          <h2 className="mb-2 text-lg font-semibold">What is the cost to target these keywords?</h2>
          <p className="mb-4 text-base font-semibold text-grey">
            For those common queries, see if anything has high volume and low CPC. If so, it may be worth targeting
            those in your PPC campaigns.
          </p>
          <Table hoverable={true} className="text-md mt-4 text-black">
            <Table.Head>
              <Table.HeadCell>Keyword</Table.HeadCell>
              <Table.HeadCell>
                <div className="flex items-center">
                  Volume <InformationCircleIcon className="ml-2 h-6 w-6" data-tooltip-id={`volume-${property.id}`} />
                </div>
              </Table.HeadCell>
              <Table.HeadCell>
                <div className="flex items-center">
                  Cost per Click{" "}
                  <InformationCircleIcon className="ml-2 h-6 w-6" data-tooltip-id={`cpc-${property.id}`} />
                </div>
              </Table.HeadCell>
              <Table.HeadCell>
                <div className="flex items-center">
                  Competition <InformationCircleIcon className="ml-2 h-6 w-6" data-tooltip-id={`comp-${property.id}`} />
                </div>
              </Table.HeadCell>
              <Table.HeadCell>
                <BrynsonTooltip
                  id={`volume-${property.id}`}
                  place="top"
                  render={() => (
                    <div className="max-w-[700px]">
                      <p className="text-lg font-medium">
                        Search volume is the <span className="font-semibold">average number</span> of times a specific
                        search query is entered on Google per month.
                      </p>
                    </div>
                  )}
                />
                <BrynsonTooltip
                  id={`cpc-${property.id}`}
                  place="top"
                  render={() => (
                    <div className="max-w-[700px]">
                      <p className="text-lg font-medium">
                        "CPC" is Cost-per-click. That’s the average price advertisers pay for a user to click on an ad
                        triggered by the keyword in Google Ads.
                      </p>
                      <p className="text-lg font-medium">
                        Cost per click is calculated by dividing the total cost of an ad by the total number of clicks.
                      </p>
                    </div>
                  )}
                />
                <BrynsonTooltip
                  id={`comp-${property.id}`}
                  place="top"
                  render={() => (
                    <div className="max-w-[700px]">
                      <p className="text-lg font-medium">
                        "Competition" is Competitive Density. It is the level of competition in Google Ads measured from
                        0 to 1. This number represents how many advertisers compete for this particular keyword in paid
                        search. The closer the score is to 1, the higher the number of advertisers currently bidding on
                        this keyword. Therefore, the harder it would be to stand out in the search results.
                      </p>
                      <p className="text-lg font-medium">
                        <span className="font-semibold"> Above 0.80:</span> These keywords are highly competitive among
                        advertisers and therefore likely send traffic to paid results. You could infer that if a keyword
                        has a high density of advertisers, the search has some intent on making a transaction.{" "}
                      </p>
                      <p className="text-lg font-medium">
                        <span className="font-semibold">From 0.60-0.80:</span> Keywords with an average density of
                        advertisers. You could experiment with advertising on these keywords if your organic efforts
                        don’t work out, but it won’t be a quick win on any of these keywords, either.
                      </p>
                      <p className="text-lg font-medium">
                        <span className="font-semibold">Below 0.60:</span> Keywords with the lowest density of
                        advertisers. This could either mean that they are under the radar of most competitors that
                        advertise in your niche, or they are simply not profitable to advertise on..
                      </p>
                    </div>
                  )}
                />
              </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              {property.semrushData
                .filter((x) => x.semrushType === "phrase_paid")
                .flatMap((x) => x.data)
                .map((row, rowIndex) => (
                  <Table.Row key={rowIndex} data={row}>
                    <Table.Cell>{row.keyword}</Table.Cell>
                    <Table.Cell>{row.search_volume}</Table.Cell>
                    <Table.Cell>${row.cpc}</Table.Cell>
                    <Table.Cell>{row.competition}</Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </div>
      )}
    </div>
  )
}
