import clsx from "clsx"
import { Label, Select, Table } from "flowbite-react"
import React, { useState, useMemo, useEffect } from "react"

export function SpecialsConcessions({ concessions = [], className = "" }) {
  const [selectedPropertyName, setSelectedPropertyName] = useState("all")

  const uniqueProperties = useMemo(() => {
    const uniqueMap = new Map()
    concessions.forEach(concession => {
      if (!uniqueMap.has(concession.building_name)) {
        uniqueMap.set(concession.building_name, {
          id: concession.id,
          building_name: concession.building_name
        })
      }
    })
    return Array.from(uniqueMap.values())
  }, [concessions])

  const filteredConcessions = useMemo(() => {
    if (selectedPropertyName === "all") {
      return concessions
    }
    return concessions.filter(concession => concession.building_name === selectedPropertyName)
  }, [concessions, selectedPropertyName])

  return (
    <div className={clsx("overflow-hidden rounded-md border", className)}>
      <div className="flex items-center justify-between p-4">
        <div>
          <p className="mb-1 text-xl font-semibold">Specials & Concessions</p>
          <p className="text-xs font-medium text-gray-500">
            Compare specials and concessions offered by your subject property to what the market is offering.
          </p>
        </div>
        <div className="flex items-center">
          <Label className="m-0 mr-2">Property</Label>
          <Select
            value={selectedPropertyName}
            onChange={(e) => setSelectedPropertyName(e.target.value)}
            className="min-w-[15rem] font-medium">
            <option value="all">All Properties</option>
            {uniqueProperties.map((property) => (
              <option key={property.id} value={property.building_name}>
                {property.building_name}
              </option>
            ))}
          </Select>
        </div>
      </div>
      <SpecialsConcessionsTable concessions={filteredConcessions} />
    </div>
  )
}

export function SpecialsConcessionsTable({ concessions = [] }) {
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 3
  const totalPages = Math.ceil(concessions.length / itemsPerPage)

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentConcession = concessions.slice(indexOfFirstItem, indexOfLastItem)

  useEffect(() => {
    setCurrentPage(1)
  }, [concessions])

  const columns = useMemo(() => {
    const allColumns = [
      { key: 'building_name', label: 'Building Name', align: 'left' },
      { key: 'concessions_or_specials', label: 'Concessions/Specials', align: 'left' },
      { key: 'active', label: 'Active', align: 'middle' },
      { key: 'from_date', label: 'From', align: 'middle' },
      { key: 'to_date', label: 'To', align: 'middle' },
      { key: 'condition_deadline', label: 'Deadline', align: 'middle' },
      { key: 'free_months', label: 'Free Months', align: 'middle' },
      { key: 'one_time_dollars_off', label: 'One Time Discount', align: 'middle' },
      { key: 'recurring_dollars_off', label: 'Recurring Discount', align: 'middle' },
      { key: 'waived_fees', label: 'Waived Fees', align: 'middle' },
      { key: 'cheaper_fees', label: 'Cheaper Fees', align: 'middle' },
    ]

    return allColumns.filter(column =>
      concessions.some(concession => {
        if (column.key === 'waived_fees') {
          return Object.keys(concession).some(key => key.startsWith('waived_') && concession[key] !== null)
        }
        if (column.key === 'cheaper_fees') {
          return Object.keys(concession).some(key => key.startsWith('cheaper_') && concession[key] !== null)
        }
        if (column.key === 'concessions_or_specials') {
          return concession.concessions != null || concession.specials != null
        }
        return concession[column.key] != null && concession[column.key] !== ''
      })
    )
  }, [concessions])

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const renderPagination = () => {
    const pageNumbers = []
    const maxButtons = 7
    const halfMaxButtons = Math.floor(maxButtons / 2)

    // Calculate the position where the ellipsis should appear
    let startPage = Math.max(1, currentPage - halfMaxButtons)
    let endPage = Math.min(startPage + maxButtons - 1, totalPages)

    // If the current page is close to the beginning, adjust endPage
    if (currentPage <= halfMaxButtons) {
      endPage = Math.min(maxButtons, totalPages)
    }

    // If the current page is close to the end, adjust startPage
    if (currentPage >= totalPages - halfMaxButtons) {
      startPage = Math.max(totalPages - maxButtons + 1, 1)
    }

    pageNumbers.push(
      <button
        key="prev"
        onClick={() => {
          if (currentPage > 1) {
            handlePageChange(currentPage - 1)
          }
        }}
        className={clsx(
          "m-1 rounded px-2 py-1",
          currentPage > 1 ? "cursor-pointer bg-gray-100 text-gray-600" : "cursor-not-allowed bg-gray-200 text-gray-400"
        )}>
        &lt;
      </button>
    )

    if (startPage > 1) {
      pageNumbers.push(
        <button
          key="first"
          onClick={() => handlePageChange(1)}
          className="m-1 rounded bg-gray-100 px-2 py-1 text-gray-600">
          1
        </button>
      )
      if (startPage > 2) {
        pageNumbers.push(
          <div key="ellipsis-start" className="m-1 px-2 py-1">
            ...
          </div>
        )
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={clsx(
            "m-1 rounded px-2 py-1",
            i === currentPage ? "bg-bgold-400 text-white-600" : "bg-gray-100 text-gray-600"
          )}>
          {i}
        </button>
      )
    }

    if (endPage < totalPages - 1) {
      pageNumbers.push(
        <div key="ellipsis-end" className="m-1 px-2 py-1">
          ...
        </div>
      )
    }

    if (endPage < totalPages) {
      pageNumbers.push(
        <button
          key="last"
          onClick={() => handlePageChange(totalPages)}
          className="m-1 rounded bg-gray-100 px-2 py-1 text-gray-600">
          {totalPages}
        </button>
      )
    }

    pageNumbers.push(
      <button
        key="next"
        onClick={() => {
          if (currentPage < totalPages) {
            handlePageChange(currentPage + 1)
          }
        }}
        className={clsx(
          "m-1 rounded px-2 py-1",
          currentPage < totalPages
            ? "cursor-pointer bg-gray-100 text-gray-600"
            : "cursor-not-allowed bg-gray-200 text-gray-400"
        )}>
        &gt;
      </button>
    )

    return <div className="flex justify-center">{pageNumbers}</div>
  }

  const getWaivedFeesUI = (concession) => {
    const waivedFees = []
    Object.keys(concession).forEach((key) => {
      if (key.startsWith("waived_") && concession[key] !== null) {
        const feeName = key.replace("waived_", "")
        waivedFees.push(
          <div
            key={feeName}
            className="my-1.5 mr-4 flex w-auto items-center justify-center rounded-full bg-gray-100 p-1 text-[11px] font-semibold text-gray-600">
            {feeName} Waived
          </div>
        )
      }
    })
    return waivedFees
  }

  const getCheaperFeesUI = (concession) => {
    const cheaperFees = []
    Object.keys(concession).forEach((key) => {
      if (key.startsWith("cheaper_") && concession[key] !== null) {
        const feeName = key.replace("cheaper_", "")
        const feeValue = concession[key]
        cheaperFees.push(
          <div
            key={feeName}
            className="my-1.5 mr-4 flex w-auto items-center justify-center rounded-full bg-gray-100 p-1 text-[11px] font-semibold text-gray-600">
            {feeName}: ${feeValue}
          </div>
        )
      }
    })
    return cheaperFees
  }

  const renderCellContent = (concession, column) => {
    switch (column.key) {
      case 'building_name':
        return concession.building_name
      case 'concessions_or_specials':
        return concession.concessions || concession.specials || ''
      case 'active':
        return concession.active ? (
          <span className="flex items-center justify-center rounded-full bg-sky-400 px-2 py-1 text-white">
            Active
          </span>
        ) : null
      case 'one_time_dollars_off':
        return concession.one_time_dollars_off ? `$${concession.one_time_dollars_off}` : null
      case 'waived_fees':
        return getWaivedFeesUI(concession)
      case 'cheaper_fees':
        return getCheaperFeesUI(concession)
      default:
        return concession[column.key]
    }
  }

  if (concessions.length === 0) {
  return (
    <div className="p-4 text-center">
      <p className="italic text-bgray">There are no concessions or specials to display.</p>
    </div>
  )
}

  return (
    <div>
      <Table>
        <Table.Head className="border-b">
          {columns.map((column) => (
            <Table.HeadCell key={column.key} className={clsx(
              column.align === 'left' ? 'text-left' : 'text-center'
            )}>
              {column.label}
            </Table.HeadCell>
          ))}
        </Table.Head>
        <Table.Body className="text-xs font-medium text-gray-800">
          {currentConcession.map((concession, index) => (
            <Table.Row key={index} className="border-b last-of-type:border-b-0 hover:bg-gray-50">
              {columns.map((column) => (
                <Table.Cell
                  key={column.key}
                  className={clsx(
                    "p-4",
                    column.align === 'left' ? 'text-left' : 'text-center',
                    column.align === 'middle' ? 'align-middle' : ''
                  )}
                >
                  {renderCellContent(concession, column)}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {renderPagination()}
    </div>
  )
}
