import { ResponsiveLine } from "@nivo/line"
import { Select, Label } from "flowbite-react"
import React, { useState, useEffect } from "react"
import { UnitCollection } from "./UnitCollection"


export default function HistoricalExposure({ properties = [] }) {
  const [timeFrame, setTimeFrame] = useState(1)
  const [futurePeriods, setFuturePeriods] = useState(10)
  const [chartData, setChartData] = useState([])
  const [newSelection, setNewSelection] = useState(1)
  const [minYScale, setMinYScale] = useState(0)

  useEffect(() => {
    initializeData()
  }, [properties, newSelection])

  const initializeData = () => {
    setFuturePeriods(10)
    setTimeFrame(1)
    const data = fetchData(properties)
    const minVal = data.reduce(
      (min, series) =>
        Math.min(min, ...series.data.map((point) => parseFloat(point.y))),
      100
    );
    setMinYScale(newSelection == "2" ? minVal - 2 : 0)
    setChartData(data)
  }

  const fetchData = (properties) => {
    return properties.map((property, index) => ({
      id: property.building_name,
      color: `hsl(${index * 60}, 70%, 50%)`,
      data: buildData(property)
    }))
  }

  const buildData = (property) => {
    const start = new Date(new Date().setDate(new Date().getDate() - 30))
    const end = new Date()
    const data = []
    let currentDate = new Date(start)

    while (currentDate <= end) {
      const toDate = new Date(currentDate)
      toDate.setDate(toDate.getDate() + timeFrame)

      const toDateString = toDate.toISOString().split("T")[0]

      let availableUnits = UnitCollection.filterAvailableUnits(property.building_availability, { startDate: currentDate, endDate: toDate }).length

      let percentage = 0
      if (newSelection === "2") {
        percentage = (((property.number_units - availableUnits) / property.number_units) * 100).toFixed(1)
      } else {
        percentage = ((availableUnits / property.number_units) * 100).toFixed(1)
      }

      if (percentage > 0) {
        data.push({ x: toDateString, y: percentage })
      }
      currentDate.setDate(currentDate.getDate() + timeFrame)
    }
    return data
  }

  return (
    <>
      {chartData.length > 0 && (
        <div className="mt-8 rounded-md border">
          <div className="flex items-center justify-between p-4">
            <div>
              <p className="mb-1 text-xl font-semibold">Historical Exposure</p>
              <p className="text-xs font-medium text-gray-500">
                Analyze the percentage of units that are listed for rent for any given day or following 6 months.
              </p>
            </div>
            <div className="flex items-center space-x-8">
              <div className="flex items-center">
                <Label className="m-0 mr-2">Metric</Label>
                <Select
                  id="metric"
                  className="min-w-[9rem] font-medium"
                  onChange={(e) => {
                    setNewSelection(e.target.value)
                  }}>
                  <option value="1">Exposure</option>
                  <option value="2">Leased % (Adv)</option>
                </Select>
              </div>
            </div>
          </div>
          <div className="my-2 border-t border-gray-200 opacity-50"></div>

          <div className="h-80" style={{ width: "100%" }}>
            <ResponsiveLine
              data={chartData}
              margin={{ top: 60, right: 110, bottom: 50, left: 60 }}
              yFormat=" >-$.2r"
              colors={{ scheme: "paired" }}
              xScale={{ type: "point" }}
              yScale={{
                type: "linear",
                min: minYScale, // Set minimum value for y-axis here
                max: "auto"
              }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "",
                legendOffset: 36,
                legendPosition: "middle",
                truncateTickAt: 0,
                tickValues: chartData[0].data.map((d, i) => (i % 3 === 0 ? d.x : null)).filter(Boolean)
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "",
                legendOffset: -40,
                legendPosition: "middle",
                truncateTickAt: 0
              }}
              pointSize={10}
              pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              pointLabel="data.yFormatted"
              enableGridX={false}
              enableGridY={false}
              enablePointLabel={false}
              enablePoints={false}
              enableCrosshair={false}
              useMesh={true}
              legends={[
                {
                  anchor: "top-right",
                  direction: "row",
                  justify: false,
                  translateX: -100,
                  translateY: -50,
                  itemsSpacing: 2,
                  itemDirection: "left-to-right",
                  itemWidth: 150,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: "circle",
                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemBackground: "rgba(0, 0, 0, .03)",
                        itemOpacity: 1
                      }
                    }
                  ]
                }
              ]}
              tooltip={({ point }) => (
                <div
                  style={{
                    padding: '6px 12px',
                    background: 'white',
                    border: '1px solid #ccc',
                    borderRadius: '4px'
                  }}
                >
                  <strong>{point.data.xFormatted}</strong>
                  <br />
                  <strong>{point.serieId}</strong>
                  <br />
                  <span>{point.data.y}% </span>
                </div>
              )}
            />
          </div>
        </div>
      )}
    </>
  )
}
