import React, { useContext, useState, useEffect } from "react"
import { useQuery } from "urql"
import { GlobalContext } from "../../context/GlobalContext"
import Selectbox from "../../shared/Selectbox"
import AGGridTable from '../../shared/AGGridTable'
import BrynsonTabs from "../../shared/BrynsonTabs"
import Loading from "../../shared/Loading"
import { ResponsivePie } from '@nivo/pie'
const apartmentscom = require('../../../../../public/ils/apartmentscomlogo.png');
const apartmentlist = require('../../../../../public/ils/apartment-list-logo.png');
const zillow = require('../../../../../public/ils/Zillow_logo.png');
const rentcom = require('../../../../../public/ils/rent-com-logo.png');

const ILS_TYPES = ['apartments_com', 'rent_com', 'zillow', 'apartment_list']
const ILS_LABELS = {
  'apartments_com': 'CoStar',
  'rent_com': 'Rent.com',
  'zillow': 'Zillow',
  'apartment_list': 'Apartment List'
}

const ILS_LOGOS = {
  'CoStar': apartmentscom,
  'Rent.com': rentcom,
  'Zillow': zillow,
  'Apartment List': apartmentlist
}

const tabs = ILS_TYPES.map(x => ({label: ILS_LABELS[x], icon: null}))

const EXTRACTED_LISTINGS_QUERY = `
  query ExtractedListings($propertyId: ID!) {
    extractedListings(propertyId: $propertyId) {
      id
      title
      listingLink
      address
      price
      beds
      baths
      featureInfo
      amenities
      virtualTour
      package
      ilsSource
      specials
      source
      sponsored
      contract
      feedName
    }
  }
`;

const PROPERTY_COMPARABLES_QUERY = `
  query PropertyComparables($propertyId: Int!) {
    propertyComparables(propertyId: $propertyId) {
      name
    }
  }
`;

export default function ILSIntelligence() {
  const [selectedProperty, setSelectedProperty] = useState(null)
  const { propertiesData } = useContext(GlobalContext)

  const propertiesOptions = propertiesData?.properties?.map(x => ({ value: x.id, label: x.name })) || []

  useEffect(() => {
    if (propertiesOptions.length > 0 && !selectedProperty) {
      setSelectedProperty(propertiesOptions[0].value)
    }
  }, [propertiesOptions, selectedProperty])

  useEffect(() => {
    if (propertiesOptions.length > 0 && !selectedProperty) {
      const firstProperty = propertiesOptions[0];
      setSelectedProperty(firstProperty.value);
    }
  }, [propertiesOptions]);

  const [result] = useQuery({
    query: EXTRACTED_LISTINGS_QUERY,
    variables: { propertyId: selectedProperty },
    pause: !selectedProperty
  })

  const { data, fetching, error } = result

  const filteredTabs = tabs.filter(tab =>
    data?.extractedListings?.some(listing => ILS_LABELS[listing.source] === tab.label)
  )

  // comparable
  const [comparableResult] = useQuery({
    query: PROPERTY_COMPARABLES_QUERY,
    variables: { propertyId: parseInt(selectedProperty) },
    pause: !selectedProperty
  })
  const { data: comparableData, fetching: comparableFetching, error: comparableError } = comparableResult

  const columnDefs = [
    { title: "Title", accessor: "title", type: "string" },
    { title: "Package", accessor: "package", type: "string" },
    { title: "Address", accessor: "address", type: "string" },
    { title: "Price", accessor: "price", type: "string" },
    { title: "Beds", accessor: "beds", type: "string" },
    { title: "Baths", accessor: "baths", type: "string" },
    { title: "Listing Link", accessor: "listingLink", type: "string", minWidth: 500 },
    { title: "Specials", accessor: "specials", type: "string" },
    { title: "Virtual Tour", accessor: "virtualTour", type: "boolean" },
    { title: "Feed Name", accessor: "feedName", type: "string" },
  ]

  const prepareChartData = (listings) => {
    const packageCounts = listings.reduce((acc, listing) => {
      acc[listing.package] = (acc[listing.package] || 0) + 1
      return acc
    }, {})

    return Object.entries(packageCounts).map(([key, value]) => ({
      id: key,
      value: value,
    }))
  }

  const renderTabContent = (ilsType) => {
    const logoDimensions = {
      'CoStar': { width: 140, height: 50 },
      'Apartment List': { width: 140, height: 50 },
      'Zillow': { width: 80, height: 80 },
      'Rent.com': { width: 80, height: 80 }
    }

    const filteredData = data?.extractedListings.filter(
      listing => ILS_LABELS[listing.source] === ilsType
    ) || []

    const seenTitles = new Set();
    const comparables = [
      data?.extractedListings.find(listing =>
        listing.title === propertiesOptions.find(p => p.value === selectedProperty)?.label
      ),
      ...comparableData?.propertyComparables
        .map(propertyComparable =>
          filteredData.find(listing => listing.title === propertyComparable.name)
        )
    ].filter(Boolean)
      .filter(item => {
        const duplicate = seenTitles.has(item.title);
        seenTitles.add(item.title);
        return !duplicate;
      });

    const hasComparables = comparables.length > 0;
    const chartData = prepareChartData(filteredData)

    return (
      <div>
        <div style={{ height: '400px' }}>
          <ResponsivePie
            data={chartData}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.6}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            colors={{ scheme: 'nivo' }}
            borderColor={{ from: 'color', modifiers: [['darker', 0.6]] }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
            layers={[
              'arcs',
              'arcLabels',
              'arcLinkLabels',
              'legends',
              ({ centerX, centerY }) => {
                const { width, height } = logoDimensions[ilsType]
                return (
                  <image
                    x={centerX - width/2}
                    y={centerY - height/2}
                    width={width}
                    height={height}
                    xlinkHref={ILS_LOGOS[ilsType]}
                    preserveAspectRatio="xMidYMid meet"
                  />
                )
              }
            ]}
          />
        </div>
        {hasComparables && (
          <>
            <h4 className="text-md font-semibold mb-4">Competitive Listings</h4>
            <AGGridTable
              data={comparables}
              headerColumns={columnDefs}
              customCellRenderers={{}}
              customHeaderRenderers={{}}
              height='350px'
            />
            <div className="mb-8"></div>
          </>
        )}
        {filteredData.length > 0 && (
          <>
            <h4 className="text-md font-semibold mb-4">All Listings</h4>
            <AGGridTable
              data={filteredData}
              headerColumns={columnDefs}
              customCellRenderers={{}}
              customHeaderRenderers={{}}
            />
          </>
        )}
      </div>
    )
  }

  return (
    <div className="p-8">
      <h1 className="font-semibold text-xl mb-6">ILS Intelligence</h1>
      {propertiesData ? (
        <Selectbox
          options={propertiesOptions}
          id="brynson-property"
          value={selectedProperty}
          defaultValue={
            selectedProperty && propertiesOptions.length > 0
              ? propertiesOptions.find(x => x.value === selectedProperty)
              : null
          }
          onChange={(value) => {
            setSelectedProperty(value)
          }}
        />
      ) : (
        <p className="text-lg text-center mt-4">Loading...</p>
      )}
      { (fetching || comparableFetching) ? (
        <Loading />
      ) : (
        <div>
          {error && <p className="text-red-500">Error: {error.message}</p>}
          {comparableError && <p className="text-red-500">Error: {comparableError.message}</p>}
          {data?.extractedListings.length > 0 ? (
            <div className="mt-4">
              <BrynsonTabs tabs={filteredTabs} className="">
                {filteredTabs.map((tab, index) => (
                  <div key={index}>
                    {renderTabContent(tab.label)}
                  </div>
                ))}
              </BrynsonTabs>
            </div>
          ) : (
            (!fetching && !comparableFetching && propertiesData) && <p className="mt-4">No data available for the selected property.</p>
          )}
        </div>
      )}
    </div>
  )
}