import { Table } from "flowbite-react"
import React from "react"

export default function RankSection({ property }) {
  return (
    <div>
      {property.semrushData &&
        property.semrushData.some((x) => x.semrushType === "rank" && x.data && x.data.length > 0) && (
          <>
            <div className="mt-6 w-full text-black">
              <h2 className="mb-2 text-lg font-semibold">Where are your leads starting their search?</h2>
              <p className="mb-2 text-base font-semibold text-grey">
                While your leads end up on Apartments.com or Zillow, they may start somewhere else.
              </p>
              <p className="mb-4 text-base font-semibold text-grey">
                If any of these have an advertising program, they may be worth considering.
              </p>
              <Table hoverable={true} className="text-md mt-4 text-black">
                <Table.Head>
                  <Table.HeadCell>Channel Type</Table.HeadCell>
                  <Table.HeadCell>Traffic</Table.HeadCell>
                  <Table.HeadCell>Website</Table.HeadCell>
                  <Table.HeadCell>Traffic Type</Table.HeadCell>
                  <Table.HeadCell>Traffic Share</Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  {property.semrushData
                    .filter((x) => x.semrushType === "rank")
                    .flatMap((x) => x.data)
                    .map((row, rowIndex) => (
                      <Table.Row key={rowIndex} data={row}>
                        <Table.Cell>{row.channel}</Table.Cell>
                        <Table.Cell>{row.traffic}</Table.Cell>
                        <Table.Cell>{row.from_target}</Table.Cell>
                        <Table.Cell>{row.traffic_type}</Table.Cell>
                        <Table.Cell>{row.traffic_share}</Table.Cell>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            </div>
          </>
        )}
    </div>
  )
}
