export const PROPERTY_AMENITIES_QUERY = `
  query {
    propertyAmenities {
      id
      name
      rank
    }
  }
`

export const FLOORPLAN_AMENITIES_QUERY = `
  query {
    floorplanAmenities {
      id
      name
      rank
    }
  }
`

export const UNIT_AMENITIES_QUERY = `
  query {
    unitAmenities {
      id
      name
      rank
    }
  }
`

export const CREATE_AMENITIES_MUTATION = `
  mutation CreateAmenityMutation($name:String, $resourceType:String, $amenityType:String, $subtype:String) {
    createAmenityMutation(name:$name, resourceType:$resourceType, amenityType:$amenityType, subtype:$subtype) {
      result
    }
  }
`

export const DELETE_AMENITIES_MUTATION = `
  mutation DeleteAmenityMutation($id:ID!) {
    deleteAmenityMutation(id:$id) {
      result
    }
  }
`

export const CREATE_PROPERTY_AMENITIES_MUTATION = `
  mutation CreatePropertyAmenityMutation($propertyId:ID!, $amenityId:ID!) {
    createPropertyAmenityMutation(propertyId:$propertyId, amenityId:$amenityId) {
      result
    }
  }
`

export const CREATE_FLOORPLAN_AMENITIES_MUTATION = `
  mutation CreateFloorplanAmenityMutation($floorplanId:ID!, $amenityId:ID!) {
    createFloorplanAmenityMutation(floorplanId:$floorplanId, amenityId:$amenityId) {
      result
    }
  }
`
export const CREATE_UNIT_AMENITIES_MUTATION = `
  mutation CreateUnitAmenityMutation($unitId:ID!, $amenityId:ID!) {
    createUnitAmenityMutation(unitId:$unitId, amenityId:$amenityId) {
      result
    }
  }
`

export const DELETE_PROPERTY_AMENITIES_MUTATION = `
  mutation DeletePropertyAmenityMutation($id:ID!, $propertyId:ID!) {
    deletePropertyAmenityMutation(id:$id, propertyId:$propertyId) {
      result
    }
  }
`

export const DELETE_FLOORPLAN_AMENITIES_MUTATION = `
  mutation DeleteFloorplanAmenityMutation($id:ID!, $floorplanId:ID!) {
    deleteFloorplanAmenityMutation(id:$id, floorplanId:$floorplanId) {
      result
    }
  }
`

export const DELETE_UNIT_AMENITIES_MUTATION = `
  mutation DeleteUnitAmenityMutation($id:ID!, $unitId:ID!) {
    deleteUnitAmenityMutation(id:$id, unitId:$unitId) {
      result
    }
  }
`
